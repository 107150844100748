import { Avatar, Box, Button, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Sell } from "../sell.entity";
import { useEffect, useState } from "react";
import { Product } from "../../product/product.entity";
import React from "react";
import { productRepository } from "../../product/product.repository";
import { SellProduct } from "../sell-product";
import { sellRepository } from "../sell.repository";
import { productCategoriesRepository } from "../../product/product-categories.repository";
import { ProductCategory } from "../../product/product-category.entity";

type AddByNameComponentProps = {
    sell: Sell,
    onClose: () => void
}

enum SearchType {
    NAME = 'name',
    CATEGORY = 'category'
}

export const AddByNameComponent = ({sell, onClose}: AddByNameComponentProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCategory, setSearchCategory] = useState<string>('');

    const [products, setProducts] = useState<Product[]>([]);
    const [searchType, setSearchType] = useState<SearchType>(SearchType.NAME);
    const [categories, setCategories] = useState<ProductCategory[]>([]);

    useEffect(() => {
        (async() => {
            const categories = await productCategoriesRepository.getAll();
            setCategories(categories);
        })();
    }, []);

    const addProduct = (product: Product) => {
        sell.addProduct(new SellProduct(
            product,
            1
        ));
        sellRepository.save(sell);
        onClose();
    };

    return (
        <>
        <div>
            <h1 className="title">Buscar produto</h1>

            <br />
            <ToggleButtonGroup
                color="primary"
                value={searchType}
                exclusive
                onChange={(event, value) => { 
                    setSearchType(value) 
                }}
                aria-label="Platform"
            >
                <ToggleButton value={SearchType.NAME}>Buscar por nome</ToggleButton>
                <ToggleButton value={SearchType.CATEGORY}>Buscar por categoria</ToggleButton>
            </ToggleButtonGroup>

            <br /><br />

            <form onSubmit={async(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (searchType === SearchType.CATEGORY) {
                    if (!searchCategory || searchCategory.trim() === '') return;

                    const productsSearched = await productRepository.getByCategoryId(searchCategory, 10);
                    setProducts(productsSearched);
                    return;
                }

                if (searchType === SearchType.NAME) {
                    const productsSearched = await productRepository.findByName(searchTerm, 10);
                    setProducts(productsSearched);
                    return;
                }

                console.error(`O SearchType ${searchType} não foi implementado.`);
            }}>
                {searchType === SearchType.NAME ? (
                    <TextField
                        id="name"
                        label="Nome do Produto"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        autoFocus={true}
                        onChange={(event) => {
                            setSearchTerm(event.target.value);
                        }}  
                    />  
                ) : null}
                
                {searchType === SearchType.CATEGORY ? (
                    <FormControl fullWidth>
                        <InputLabel id="category-label">Categoria</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category"
                            value={searchCategory}
                            label="Categoria"
                            fullWidth
                            onChange={(event) => setSearchCategory(event.target.value)}
                        >
                            {categories.map((category) => (
                                <MenuItem value={category.id} key={category.id}>{category.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}

                <br /><br />

                <Button
                    fullWidth
                    variant='contained'
                    color="info"
                    type="submit"
                >Buscar</Button>
            </form>

            <List sx={{maxHeight: '50vh'}}>
                {products.map((product) => (
                    <ListItem disablePadding key={product.id} onClick={() => addProduct(product)}>
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar alt="Imagem do Produto" src={product.getImage()} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={product.getName()}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="p"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {product.getPrice().toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL' 
                                                })}
                                            </Typography>

                                            <Typography
                                                component="p"
                                                variant="subtitle2"
                                            >
                                                {product.id}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
        </>
    );
};