import { useEffect, useState } from "react";

export type EventCallback<T> = (event: T) => void;

export class AppEvent<T> {
    private listeners: EventCallback<T>[] = [];
    
    raiseEvent(event: T) {
        this.listeners.forEach((listener) => {
            listener(event);
        });
    }

    addListener(callback: EventCallback<T>) {
        return this.listeners.push(callback);
    }

    removeListener(id: number) {
        this.listeners = this.listeners.splice(id, 1);
    }

    get useEventState() {
        return (initialValue: T|null = null) => {
            const [state, setState] = useState<T|null>(initialValue);

            useEffect(() => {
                const listener = this.addListener((value: T) => {
                    setState(value);
                });

                return () => this.removeListener(listener)
            }, []);

            return [state];
        };
    }
};