import { Alert, Button, FormControl, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { Sell } from "../sell.entity";
import { sellRepository } from "../sell.repository";

type SaveSellComponentProps = {
    closeSave: () => void,
    onSellSaved: () => void,
    sell: Sell
}

export const SaveSellComponent = ({closeSave, sell, onSellSaved}: SaveSellComponentProps) => {
    const [customer, setCustomer] = useState<string>(sell.getCustomer());
    const [error, setError] = useState<string|null>(null);

    const saveSell = useCallback(() => {
        if (customer.length === 0) {
            setError('Você precisa informar o nome do cliente.');
            return;
        } 

        sell.setCustomer(customer);
        sellRepository.save(sell);
        onSellSaved();
    }, [sell, customer, setError, onSellSaved])

    return (
        <>
        <br />

        {error ? (
            <>
                <Alert color="error">{error}</Alert>
                <br />
            </>
        ) : null}


        <form onSubmit={(event) => {
            event.preventDefault();
            saveSell();
        }}>
            <FormControl fullWidth>
                <TextField
                    id="name"
                    label="Nome do cliente"
                    variant="outlined"
                    className="sell"
                    placeholder="Nome do cliente"
                    autoFocus={true}
                    onBlur={event => event.target.focus()}
                    value={customer}
                    onChange={event => setCustomer(event.target.value)}
                    fullWidth
                />
            </FormControl>
            <br /><br />
            <Button fullWidth color="success" variant="contained" type="submit">Salvar</Button>
            <Button fullWidth color="error" onClick={() => closeSave()}>Cancelar</Button>
        </form>
        </>
    );
}