import { useState } from "react";

export function useLocalStorageState<Type>(key: string, defaultValue: Type|null = null): [Type|null, (newValue: Type|null) => void] {
    const storage = localStorage.getItem(`_state_${key}`);
    const [state, setState] = useState<Type|null>(
        storage ? JSON.parse(storage).value : defaultValue
    );
    
    const setValue = (newValue: Type|null) => {
        localStorage.setItem(`_state_${key}`, JSON.stringify({value: newValue}));
        setState(newValue);
    };

    return [state, setValue];
}