import { useEffect, useState } from "react";
import { AppEvent, EventCallback } from "../app-event";
import ValueObject from "./value-object";

export default class ValueObjectObservable<T> extends ValueObject<T> {
    protected valueChange = new AppEvent<T>();

    set(value: T) {
        super.set(value);
        this.valueChange.raiseEvent(value);
    }

    addEventListener(callback: EventCallback<T>) {
        return this.valueChange.addListener(callback);
    }

    removeEventListener(id: number) {
        this.valueChange.removeListener(id);
    }
}