import { getDocs, limit, orderBy, query } from "firebase/firestore";
import { PagenedResult } from "../common/pagened-result";
import { RepositoryFirebase } from "../common/repository.firebase";
import { ProductSupplier } from "./product-supplier.entity";

export class ProductSupplierRepository extends RepositoryFirebase<ProductSupplier> {
    protected collection = 'products-supplier'
    protected entity = ProductSupplier;
}

export const productSupplierRepository = new ProductSupplierRepository();