export default class ValueObject<T> {
    constructor(protected value: T) {}

    public get() {
        return this.value;
    }

    public set(value: T) {
        this.value = value;
    }
}