import * as qz from 'qz-tray';
import { qzDebug } from "./qz.debug";
import Swal from "sweetalert2";
import { AppError, AppErrorKey } from '../common/app-error';

export class Print {
    private config: any = undefined;

    constructor() { }

    async configure() {
        qzDebug();

        if (!await qz.websocket.isActive()) {
            await qz.websocket.connect();
        }

        const printerSaved = localStorage.getItem('_printer');
        if (printerSaved) {
            this.config = qz.configs.create(printerSaved);
            return;
        }

        const printers = await qz.printers.find();
        
        const printerSelect = await Swal.fire({
            title: 'Configurar a impressora',
            input: 'select',
            inputOptions: printers,
            inputPlaceholder: 'Selecione a impressora',
            showCancelButton: false
        });

        if (!printerSelect.value) {
            throw new Error('Impressora não foi selecioanda.');
        }

        const printer = printers[printerSelect.value];

        localStorage.setItem('_printer', printer);
        this.config = qz.configs.create(printer);
    }

    async print(data: any) {
        await qz.print(this.config, data);
    }
}