import { Alert, Avatar, Backdrop, Badge, Box, Button, Chip, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from "@mui/material"

import { Sell, SellPaymentMethod } from "../sell.entity";
import { AppError, AppErrorKey } from "../../common/app-error";
import { SwalReact } from "../../common/swal-react";
import { AddProductSell } from "./addproduct.sell";
import { Product, noImage } from "../../product/product.entity";
import { productRepository } from "../../product/product.repository";
import { useCallback, useEffect, useState } from "react";

import './sell-component.scss';
import { grey } from "@mui/material/colors";
import { SellProduct } from "../sell-product";
import { FinishSellComponent } from "./finish-sell.component";
import { sellRepository } from "../sell.repository";
import { SaveSellComponent } from "./savesell-component";
import { Cancel, CheckCircle, Delete, Edit, Paid, Save } from "@mui/icons-material";
import { Shell } from "../../common/shell/shell.page";
import { RepositoryErrorKey } from "../../common/repository.firebase";
import { XeroxComponent } from "./xerox-component";
import { AddByNameComponent } from "./add-by-name-component";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import React from "react";
import Swal from "sweetalert2";
import { MoneyInput } from "../../common/components/money-input";
import ValueObjectObservable from "../../common/vo/value-object-observable";
import { MoneyInputValueObject } from "../../common/components/money-input-value-object";
import { productCategoriesRepository } from "../../product/product-categories.repository";
import { BarcodeScanner } from "../../common/components/barcode-scanner";
import { SellUnregisterProduct } from "../sell-unregister-product";
import { v4 } from "uuid";

type SellComponentProps = {
    sellId: string;
    closeSell: () => void,
}

export const SellComponent = ({sellId, closeSell}: SellComponentProps) => {
    const [cancelFocusAddInput, setCancelFocusAddInput] = useState(false);
    const [removeMode, setRemoveMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFinishSell, setIsFinishSell] = useState(false);

    const [sell] = sellRepository.useFindById(sellId, () => {
        closeSell();
    });

    useEffect(() => {
        if (!sell) return;
        if (sell.getPaymentMethod() !== SellPaymentMethod.UNPAID && !isFinishSell) return closeSell();
    }, [sell, closeSell]);
    

    const addProduct = async(code: string) => {
        if (!sell) return;
        if (!code) return;

        if (removeMode) {
            sell.removeProduct(code);
            sellRepository.save(sell);
            return;
        }

        if (code.toString() === '1') {
            return addOnlyPrice();
        }

        setCancelFocusAddInput(true);

        try {
            setLoading(true);
            const product = await productRepository.findById(code);
            sell.addProduct(
                new SellProduct(
                    product,
                    1
                )
            );
            await sellRepository.save(sell);
            setLoading(false);
        } catch(err) {            
            if (err instanceof AppError) {
                if (err.key === RepositoryErrorKey.ENTITY_NOT_FOUND) {
                    const addProductSellModal = SwalReact.mixin({
                        title: 'Adicionar Produto',
                        html: <AddProductSell addOnlyPrice={() => {
                            setLoading(false);
                            addProductSellModal.close();
                            addOnlyPrice();
                        }} />,
                        focusConfirm: false,
                        preConfirm: () => {
                            const name = (document.getElementById("name") as HTMLInputElement)?.value;
                            const price = (document.getElementById("amount") as HTMLInputElement)?.dataset.amount;
                            const category = (document.getElementById("categoryId") as HTMLInputElement)?.value;

                            if (!name || !price || !category) {
                                (document.getElementById("amountError") as HTMLElement).style.opacity = '1';
                                return false;
                            }

                            return [name, price, category];
                        },
                        didClose: () => { setCancelFocusAddInput(false); setLoading(false); }
                    });

                    const { value: formValues } = await addProductSellModal.fire();

                    if (formValues) {
                        setLoading(true);

                        let category = null;
                        if (formValues[2]) {
                            category = await productCategoriesRepository.findById(formValues[2] as string);
                        }
                        const newProduct = new Product(code, formValues[0] || '', formValues[1] as any, noImage, category)
                        await productRepository.save(newProduct);
                        sell.addProduct(
                            new SellProduct(
                                newProduct,
                                1
                            )
                        );
                        await sellRepository.save(sell);

                        setLoading(false);
                    }
                }
                return;    
            }

            console.error(err);
            throw new AppError(AppErrorKey.UNKNOWN_ERROR, (err as any).toString());
        }


        setCancelFocusAddInput(false);
    };

    const finishSell = useCallback(() => {
        if (!sell) return;
        /*setCancelFocusAddInput(true);
        const finishModal = SwalReact.mixin({
            html: <FinishSellComponent sell={sell} onCompleteSell={() => {
                finishModal.close();
                closeSell();
            }} onClose={() => finishModal.close()} />,
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: true,
            willClose: () => setCancelFocusAddInput(false)
        });
        finishModal.fire();*/
        setIsFinishSell(true);
    }, [closeSell, sell]);

    const addXerox = useCallback(() => {
        if (!sell) return;
        setCancelFocusAddInput(true);
        const xeroxModal = SwalReact.mixin({
            html: <XeroxComponent sell={sell} onClose={() => xeroxModal.close()} />,
            showConfirmButton: false,
            allowOutsideClick: true,
            showCloseButton: true,
            willClose: () => setCancelFocusAddInput(false)
        });
        xeroxModal.fire();
    }, [sell]);

    const addProductByName = useCallback(() => {
        if (!sell) return;
        setCancelFocusAddInput(true);
        const addProductByNameModal = SwalReact.mixin({
            html: <AddByNameComponent sell={sell} onClose={() => addProductByNameModal.close()} />,
            showConfirmButton: false,
            allowOutsideClick: true,
            showCloseButton: true,
            willClose: () => setCancelFocusAddInput(false)
        });
        addProductByNameModal.fire();
    }, [sell]);

    const saveSell = useCallback(() => {
        if (!sell) return;
        setCancelFocusAddInput(true);
        const saveModal = SwalReact.mixin({
            html: <SaveSellComponent
                    sell={sell}
                    closeSave={() => saveModal.close()}
                    onSellSaved={() => { saveModal.close(); closeSell()}} 
                   />,
            showConfirmButton: false,
            allowOutsideClick: true,
            showCloseButton: true,
            willClose: () => setCancelFocusAddInput(false)
        });
        saveModal.fire();
    }, [closeSell, sell]);


    const cancelSell = useCallback(() => {
        if (!sell) return;
        sellRepository.remove(sell);
        closeSell();
    }, [sell, closeSell]);

    const changeProductPrice = useCallback(async(productId: string) => {
        if (!sell) return;

        const product = sell.getProduct(productId);
        if (!product) return;

        const newMoney = new ValueObjectObservable(product.price);
        setCancelFocusAddInput(true);

        const changeValueModal = await SwalReact.mixin({
            title: 'Mudar valor',
            html: (
                <MoneyInputValueObject title="Novo Valor" valueObject={newMoney} textFieldProps={{
                    fullWidth: true,
                    sx: {mt: 2},
                    autoFocus: true,
                    onKeyUp: (event) => {
                        if (event.key === 'Enter') {
                            changeValueModal.clickConfirm();
                        }
                    }
                }}  />
            ),
            showCancelButton: true,
            confirmButtonText: `Salvar`,
            cancelButtonText: `Cancelar`,
            icon: 'info'
        });

        const result = await changeValueModal.fire();

        if (result.isConfirmed) {
            product.price = newMoney.get();
            sell.changeProduct(product);
            sellRepository.save(sell);
        }

        setCancelFocusAddInput(false);
    }, [sell]);

    const addOnlyPrice = useCallback(async() => {
        if (!sell) return;

        const price = new ValueObjectObservable(0);
        setCancelFocusAddInput(true);

        const changeValueModal = SwalReact.mixin({
            title: 'Adicionar só valor',
            html: (
                <MoneyInputValueObject title="Valor" valueObject={price} textFieldProps={{
                    fullWidth: true,
                    sx: {mt: 2},
                    autoFocus: true,
                    onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            changeValueModal.clickConfirm();
                        }
                    }
                }}  />
            ),
            showCancelButton: true,
            confirmButtonText: `Salvar`,
            cancelButtonText: `Cancelar`,
            icon: 'info'
        });

        const result = await changeValueModal.fire();

        if (result.isConfirmed) {
            const newProduct = new SellProduct(
                new SellUnregisterProduct(v4(), 'Produto', price.get(), noImage),
                1,
                price.get()
            );
            sell.addProduct(newProduct);
            sellRepository.save(sell);
        }

        setCancelFocusAddInput(false);
    }, [sell]);

    const readWithCamera = async() => {
        setCancelFocusAddInput(true);

        const cameraModal = SwalReact.mixin({
            html: (
                <Box sx={{width: '400px', height: '300px', position: 'relative'}}>
                    <BarcodeScanner onDetected={(result) => {
                        addProduct(result);
                        cameraModal.close();
                    }} />
                </Box>
            ),
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            allowOutsideClick: true,
            willClose: () => setCancelFocusAddInput(false),
            width: '450px'
        });

        cameraModal.fire();
    };

    useEffect(() => {
        const keyEvent = (event: KeyboardEvent) => {
            if (cancelFocusAddInput) return;
            if (!sell) return;

            if (event.key === 'F2') {
                finishSell();
                return;
            }

            if (event.key === 'F3') {
                saveSell();
                return;
            }

            if (event.key === 'F9') {
                cancelSell();
                return;
            }

            if (event.key === 'Delete') {
                setRemoveMode(removeMode => !!!removeMode);
                return;
            }

            if (!isNaN(parseInt(event.key)) && !cancelFocusAddInput) {
                const element = document.getElementById('code') as any;
                
                if (!element) return;
                element.focus();
                return;
            }
        };

        document.addEventListener('keydown', keyEvent);

        return () => document.removeEventListener('keydown', keyEvent);
    }, [cancelFocusAddInput, sell, setRemoveMode, cancelSell, finishSell]);

    if (!sell) {
        return (
            <Backdrop
            sx={{ color: '#fff', zIndex: 999 }}
            open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    if (loading) {
        return (
            <Backdrop
            sx={{ color: '#fff', zIndex: 999 }}
            open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    if (isFinishSell) {
        return (
            <Shell title="Finalizar Venda">
                <>
                    <FinishSellComponent sell={sell} onCompleteSell={() => {
                        //pass
                    }} onClose={() => setIsFinishSell(false)} closeSell={() => closeSell()} />

                    <div className="sell-bottom">
                        <div className="pricing">
                            <p>{sell.getSellTotal().toLocaleString('pt-BR', {
                                currency: 'BRL',
                                style: 'currency'
                            })}</p>
                            <small>Total da venda</small>
                        </div>
                    </div>
                </>
            </Shell>
        )
    }

    return (
        <Shell title='Vender' speedDialOnlySize={750} speedDial={[
            {
                title: 'Finalizar',
                icon: <CheckCircle />,
                tooltipOpen: true,
                color: 'success',
                onClick: () => finishSell()
            },
            {
                title: 'Salvar',
                icon: <Save />,
                tooltipOpen: true,
                color: 'info',
                onClick: () => saveSell()
            },
            {
                title: 'Cancelar',
                icon: <Cancel />,
                tooltipOpen: true,
                color: 'danger',
                onClick: () => cancelSell()
            }
        ]}>
            <>
            <div id="sellPage" className='sell-form'>
                {removeMode ? (
                    <Alert severity="error">Modo remoção: Digite o código para remover o item da venda ou aperte `Delete` novamente para voltar ao modo adição.</Alert>
                ) : null}


                <div className='add-sell'>
                    <form className='sell-form' onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ((document.getElementById("code") as any) === '') return;

                        addProduct((document.getElementById("code") as any).value);
                        (document.getElementById("code") as any).value = '';
                    }}>
                        <TextField
                            id="code"
                            label="Código"
                            variant="outlined"
                            className="sell"
                            color={removeMode ? 'error' : 'info'}
                            onBlur={e => {
                                //if ((('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || ((navigator as any).msMaxTouchPoints > 0))) return;
                                ///if (!cancelFocusAddInput) e.target.focus();
                            }}
                            autoFocus={true} 
                        />
                    </form>

                    <div className="actions">
                        <Button fullWidth color='info' variant="contained" onClick={() => addXerox()}>Adicionar Xerox</Button>
                        <Button fullWidth color='info' variant="contained" onClick={() => addOnlyPrice()}>Adicionar Valor a Venda</Button>
                        <Button fullWidth color='info' variant="contained" onClick={() => addProductByName()}>Adicionar por nome</Button>
                    </div>
                </div>
            </div>
            <div className='products'>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem component="a" href="#customized-list">
                        <ListItemText
                            sx={{ my: 0 }}
                            primary="Produtos"
                            primaryTypographyProps={{
                                fontSize: 20,
                                fontWeight: 'medium',
                                letterSpacing: 0,
                            }}
                        />
                    </ListItem>
                    {sell.getProducts().getValues().map(product => (
                        <>
                        <Divider />
                        <ListItem key={product.id} secondaryAction={
                            <>
                                <Typography
                                        component="p"
                                        variant="subtitle1"
                                        color="text.primary"
                                    >
                                    {(product.price * product.quantity).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                            </>
                        }>
                            <ListItemAvatar>
                                <Avatar src={product.entity.getImage()} />
                            </ListItemAvatar>
                            <ListItemText primary={product.entity.getName() || 'Produto sem título'} secondary={
                                <>
                                    <Typography
                                        component="p"
                                        variant="subtitle1"
                                        color="text.primary"
                                    >
                                        {product.quantity}x {product.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </Typography>
                                    
                                    <Typography
                                        component="p"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {product.entity instanceof SellUnregisterProduct ? (
                                            'Produto não cadastrado'
                                        ) : product.id}
                                    </Typography>


                                    <Box sx={{width: '100%', textAlign: 'center'}}>
                                        <IconButton edge="end" aria-label="Mudar Valor" onClick={(event) => {
                                            event.preventDefault();
                                            changeProductPrice(product.id);
                                        }}>
                                            <Paid />
                                        </IconButton>

                                        <IconButton edge="end" aria-label="Adicionar" onClick={(event) => {
                                            event.preventDefault();
                                            sell.incrementAmount(product.id);
                                            sellRepository.save(sell);
                                        }}>
                                            <AddCircleOutlineIcon />
                                        </IconButton>

                                        <IconButton edge="end" aria-label="Remover" onClick={(event) => {
                                            event.preventDefault();
                                            sell.decrementAmount(product.id);
                                            sellRepository.save(sell);
                                        }}>
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>

                                        <IconButton edge="end" aria-label="Remover" onClick={(event) => {
                                            event.preventDefault();
                                            sell.removeProduct(product.id);
                                            sellRepository.save(sell);
                                        }}>
                                            <Delete />
                                        </IconButton>

                                    </Box>
                                </>


                            } />
                        </ListItem>
                        </>
                    ))}
                </List>
                {/*
                
                {sell.getProducts().getValues().map((product) => (
                    <div className="product" key={product.id}>
                        <div className="image">
                            <Badge
                                badgeContent={product.quantity}
                                color="warning"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                            }}>
                            <img src={product.image} aria-label={`Imagem de ${product.name}`} style={{width: '100%', maxHeight: 200}} />
                            </Badge>
                        </div>

                        <small className="code">{product.id}</small>
                        <div className='title'>
                            {product.name || 'Produto sem título'}
                        </div>

                        <div className='price'>
                            {product.quantity}x {(parseFloat(product.price as any)).toLocaleString('pt-BR', {
                                currency: 'BRL',
                                style: 'currency'
                            })}
                            <p><strong>
                                {(product.price * product.quantity).toLocaleString('pt-BR', {
                                    currency: 'BRL',
                                    style: 'currency'
                                })}
                            </strong></p>
                        </div>

                        <Button fullWidth variant='contained' color='error' onClick={() => {
                            sell.removeProduct(product.id);
                            sellRepository.save(sell);
                        }}>Remover</Button>
                    </div>
                ))}

                */}
            </div>

            <div className="sell-bottom">
                <div className="pricing">
                    <p>{sell.getSellTotal().toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency'
                    })}</p>
                    <small>Total da venda</small>
                </div>

                <div className="actions">
                    <Button variant="contained" color="error" onClick={() => cancelSell()}>
                        <Chip label="F9" sx={{bgcolor: grey[500]}} /> &nbsp; Cancelar Compra</Button>

                    <Button variant="contained" color="info" onClick={() => saveSell()}>
                        <Chip label="F3" sx={{bgcolor: grey[500]}} /> &nbsp; Salvar Venda
                    </Button>

                    <Button variant="contained" color="success" onClick={() => finishSell()}>
                        <Chip label="F2" sx={{bgcolor: grey[500]}} /> &nbsp; Finalizar Compra
                    </Button>
                </div>
            </div>
            </>
        </Shell>
    )
};