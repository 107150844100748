export enum AppErrorKey {
    UNKNOWN_ERROR = 'unknown_error',
    BUSSINESS_NOT_DEFINED = 'bussiness_not_defined',
    PRINTER_NOT_INITIALIZED = 'printer_not_initialized',
    USER_NOT_LOGGED = 'user_not_logged'
}

export class AppError extends Error {
    public readonly key: string;

    constructor(key: string, message = '', options?: ErrorOptions) {
        super(`[${key}] ${message}`, options);

        this.key = key;
    }
}