import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Functions, getFunctions } from "firebase/functions";
import { Auth, browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { get } from "http";

class Firebase {
    public readonly app: FirebaseApp;
    public readonly analytics: Analytics;

    public readonly db: Firestore;
    public readonly storage: FirebaseStorage;
    public readonly auth: Auth; 
    public readonly functions: Functions;

    static instance = new Firebase();

    constructor() {
        const firebaseConfig = {
            apiKey: "AIzaSyD4jXjsXTzFGrbdLbgCPliIqhq_aTmiRsk",
            authDomain: "simplou-40565.firebaseapp.com",
            projectId: "simplou-40565",
            storageBucket: "simplou-40565.appspot.com",
            messagingSenderId: "647500618453",
            appId: "1:647500618453:web:f8386a3bcdd1779fa2e05c",
            measurementId: "G-QYMP8T07PG"
        };

        this.app = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(this.app);

        this.db = getFirestore(this.app);
        this.storage = getStorage(this.app);
        this.auth = getAuth(this.app);
        this.functions = getFunctions(this.app);

        setPersistence(this.auth, browserLocalPersistence);
    }
}

export default Firebase.instance;