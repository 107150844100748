import { DocumentData, DocumentSnapshot, Query, endAt, endBefore, getDocs, limit, limitToLast, orderBy, query, startAfter, startAt } from "firebase/firestore";
import { EntityFirebase } from "./entity.firebase";
export class PagenedResult<T> {
    private nextCache: PagenedResult<T>|null = null;
    private previusCache: PagenedResult<T>|null = null;

    constructor(
        public readonly itens: Array<T>,
        public readonly firstVisible: DocumentSnapshot,
        public readonly lastVisible: DocumentSnapshot,
        public readonly query: Query<DocumentData>,
        public readonly limit: number,
        public readonly entity: new (...args: any[]) => EntityFirebase
    ) { }

    async next() {
        if (this.nextCache) return this.nextCache;
        if (!this.lastVisible) return this;

        const querySnap = query(this.query, limit(this.limit), startAfter(this.lastVisible));
        const snapshot = await getDocs(querySnap);

        if (snapshot.docs.length === 0) {
            return this;
        }

        const items = await Promise.all(snapshot.docs.map(async(item) => {
            return await (this.entity as any).build(item.id, item.data());
        }));

        this.nextCache = new PagenedResult<T>(
            items.length > 0 ? items : this.itens,
            snapshot.docs[0] ?? this.firstVisible,
            snapshot.docs[snapshot.docs.length - 1] ?? this.lastVisible,
            this.query,
            this.limit,
            this.entity
        );

        return this.nextCache;
    }

    async previus() {
        if (this.previusCache) return this.previusCache;
        if (!this.firstVisible) return this;

        const querySnap = query(this.query, limitToLast(this.limit), endBefore(this.firstVisible));
        const snapshot = await getDocs(querySnap);

        if (snapshot.docs.length === 0) {
            return this;
        }

        const items = await Promise.all(snapshot.docs.map(async(item) => {
            return await (this.entity as any).build(item.id, item.data());
        }));

        this.previusCache = new PagenedResult<T>(
            items.length > 0 ? items : this.itens,
            snapshot.docs[0] ?? this.firstVisible,
            snapshot.docs[snapshot.docs.length - 1] ?? this.lastVisible,
            this.query,
            this.limit,
            this.entity
        );

        return this.previusCache;
    }

    async hasNext() {
        return await this.next() === this;
    }

    async hasPrevius() {
        return await this.previus() === this;
    }

    async refreshItens() {
        const querySnap = query(this.query, limit(this.limit));
        const snapshot = await getDocs(querySnap);

        const items = await Promise.all(snapshot.docs.map(async(item) => {
            return await (this.entity as any).build(item.id, item.data());
        }));

        return new PagenedResult<T>(
            items,
            snapshot.docs[0],
            snapshot.docs[snapshot.docs.length - 1],
            this.query,
            this.limit,
            this.entity
        );
    }
}
