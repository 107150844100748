import { KEYUTIL, KJUR, hextorstr, stob64 } from 'jsrsasign';
import * as qz from 'qz-tray';

export const qzDebug = () => {
    qz.security.setCertificatePromise((resolve: any) => {
        resolve("-----BEGIN CERTIFICATE-----\n" +
        "MIIECzCCAvOgAwIBAgIGAYmsqATBMA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
        "EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
        "UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
        "HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
        "RGVtbyBDZXJ0MB4XDTIzMDczMDE1NTM1OVoXDTQzMDczMDE1NTM1OVowgaIxCzAJ\n" +
        "BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
        "VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
        "IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
        "VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDU\n" +
        "VrVmgYCzuKFmpYa5QhvYj0iVNXVA8dmQI4Eyg+B3IjOhjhVtItQS8fUsn3SbIOzj\n" +
        "p/I6Fi/Ouh/K5hzmyKWu0oebC9YIgABe3t3YoY01yGehLsxql0ty8WwiMamF8Y9b\n" +
        "NY3junfn/liQPXMdGaTyCZnWme7xry7nxbEe8+IdgscAxN4u6cdrqlHuZXYoas4q\n" +
        "f26/V8vh51r3s23KaqJnS6Pg+zLR0XCfgnJlHAbVGBvrYzZ3p5Kdjgs0Bll1zEwD\n" +
        "SnjKIK8v+98Tvl9H7F7f8FK829tS2fu46DIBb1XJmLlQ040Rq3EyNGblIaxf2m3J\n" +
        "R0W+YF0q6RG29UXA1OvVAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
        "VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBTsF1d4/Wr9L7tBNCXT2xuqB6+hgDANBgkq\n" +
        "hkiG9w0BAQsFAAOCAQEAjwI7I+vLmIC2WSuE0aWDp3920HT7+s00wbaf/kE0r9kR\n" +
        "iR+TmpS2p05eyR3tG4+Pa636Uagj1vy5fPD2CdvHuLp1dN/kQFgqDxOUWJ26x39o\n" +
        "3xaXVp1OgFqfeuziCd2XE49sm/eQAdGKZBEt6dAP+HsfVugswXNfHezoHD9AJ7dt\n" +
        "u6sg2/5j5tYkNnodroi5mzjFFGvDtcNn7zL629zHXbhhIczshNyDWXZOSxKjr2Ei\n" +
        "JuofFC+yCEOKKbKo5reG9QTfOflYChSShov361rPowe5bewCuu+zIOzdZWEIJE4o\n" +
        "BySE/ik1EPeXvUrq3omj/uXjuQjGmUMRQQjV3Cz9Yw==\n" +
        "-----END CERTIFICATE-----\n")
    });

    var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
    "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDUVrVmgYCzuKFm\n" +
    "pYa5QhvYj0iVNXVA8dmQI4Eyg+B3IjOhjhVtItQS8fUsn3SbIOzjp/I6Fi/Ouh/K\n" +
    "5hzmyKWu0oebC9YIgABe3t3YoY01yGehLsxql0ty8WwiMamF8Y9bNY3junfn/liQ\n" +
    "PXMdGaTyCZnWme7xry7nxbEe8+IdgscAxN4u6cdrqlHuZXYoas4qf26/V8vh51r3\n" +
    "s23KaqJnS6Pg+zLR0XCfgnJlHAbVGBvrYzZ3p5Kdjgs0Bll1zEwDSnjKIK8v+98T\n" +
    "vl9H7F7f8FK829tS2fu46DIBb1XJmLlQ040Rq3EyNGblIaxf2m3JR0W+YF0q6RG2\n" +
    "9UXA1OvVAgMBAAECggEABy5OK4LyVPbye7kvVDE4ezC6oKkn0k7GL8oI7X9o5j6B\n" +
    "hn4lRVqUgiDX926xD9Sjjj4OGAl2C8S0RzVUVuMEOg75R7S1GUvS4g0hbYO7yyIq\n" +
    "4a2RJi2q/zPLl2Bx6OC2xhE1P4qn0XBYPbsw8GlUmwbYwq7UANe5e1xugAB5biFV\n" +
    "PxAPcLYrOmZI+8w9GNPj8EaQTfVysuQpGR652PumUkV0voNnyf1hXkHJFtWfWGE7\n" +
    "c8S9jZguuWXFdESNY/45SL00UtRcclu0bHQJt2JqPIaSZ+VtrGBCiarhkyb/DRWA\n" +
    "DFdaAH5fC1TCoSEIoIcdUdWsiwj9leAo+e6r0m17hQKBgQD/au7W5uTObA7UulUh\n" +
    "mx8+c5+ns6PacPPu7W2/zKiX+wrNjr284xLbyt0aB1ZCFDOEQ0nOOud4clzcAVln\n" +
    "4l98ksOoSaRDY5gXilkz63h7UrAWVVfqXpoDHsetuFTuUJvzMpfB//moxkP+aZxK\n" +
    "jQt9IFyOZJc3zUdNJqbL54wUDwKBgQDU0qJDGfBrZjum2l3sjN+DX+DncUzCPK5t\n" +
    "eIYY6A/44AQeIY5lQQrQAvwM3oSkEKQVGJ5RSjfYYPXyZhM0vMhCVXry13+fk9nt\n" +
    "yhovs5sqjm2ZaOHSBWQYkS9GBh7iq3oVLlu11XkB3M+lwIRe6NKnZXZH68MlHJfG\n" +
    "P0MRulUN2wKBgH6cnG6tIEjvKd7ryx2QjslfeYohM50qFtGeoqPMKNrF2BITqxyC\n" +
    "F6wagMXRU0IIT41F6cUwT+PWFQ/+Hzqu7ZSMEC9Q6ft52D/7FcL1+9BuKdm4To9g\n" +
    "e8oGLQeBc6B7FwNUVgQWBsVSGwx3f8E477QEIb14Qbmoqaql+VLkofyjAoGAKLmp\n" +
    "3UdQb2zfy10MDiEjAGPPiNpwgtbT1hTzLD70F37eqtlnA08//3AixV+raC3bCEJu\n" +
    "wUV/jEN5rQoFcNkTRUZQQbolLOdBE7TR8ADhl3Ir3OhqFiN79QuLTU4MiPmhYf4+\n" +
    "BHy2LDoR7jj526OX8/qBeN/PtwFStdgTQIg0kHMCgYEAjlakeoiJpUzJVlZ/U0Y0\n" +
    "NutctT6zQY6SqLxOxh7zR7Ejb8revvQAXd1E2O3Oe0C0+1n+9KyS9sPzSit9n9Zr\n" +
    "BQhlF74vIwekTgV7jMB/DY+yZsbBX9P2EQLBQR8KRdha2XO/xmTZunLZw2f+jRnn\n" +
    "VbUhg2hcdySjnyBBLq9WgSA=\n" +
    "-----END PRIVATE KEY-----\n";

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function(toSign: any) {
        return function(resolve: any, reject: any) {
            try {
                var pk = KEYUTIL.getKey(privateKey);
                var sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});  // Use "SHA1withRSA" for QZ Tray 2.0 and older
                sig.init(pk); 
                sig.updateString(toSign);
                var hex = sig.sign();
                resolve(stob64(hextorstr(hex)));
            } catch (err) {
                console.error(err);
                reject(err);
            }
        };
    });
}