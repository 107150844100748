import { collection, getDocs, query, where } from 'firebase/firestore';
import firebase from '../core/firebase';
import { Bussiness } from './bussiness.entity';
import { AppError } from '../common/app-error';
import { getDownloadURL, ref } from 'firebase/storage';

export enum BussinessQueryErrorKey {
    DUPLICATE_DOMAIN = 'duplicate_domain',
    DOMAIN_NOT_FOUND = 'domain_not_found'
}

class BussinessQuery {
    private ref = collection(firebase.db, "bussiness");

    static instance = new BussinessQuery();
    private constructor() {}

    async queryByDomain(domain: string) {
        const q = query(this.ref, where("domain", "array-contains", domain));
        const bSnapshot = await getDocs(q);
        if (bSnapshot.size === 0) throw new AppError(BussinessQueryErrorKey.DOMAIN_NOT_FOUND);  
        if (bSnapshot.size > 1) throw new AppError(BussinessQueryErrorKey.DUPLICATE_DOMAIN);

        const bId = bSnapshot.docs[0].id;
        const bData = bSnapshot.docs[0].data();

        const imageRef = ref(firebase.storage, bData.image);
        return new Bussiness(bId, bData.name, bData.domain, await getDownloadURL(imageRef), bData.key);
    }
}

export default BussinessQuery.instance;
