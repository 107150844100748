import { Box, Button, Typography } from "@mui/material";
import { Shell } from "../../common/shell/shell.page";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import application from "../../core/application";


export const NotPermissionPage = () => {
    const [user] = application.user.useState();

    if (!user) {
        return (
            <Box sx={{textAlign: 'center'}}>
                <ErrorIcon color="error" sx={{fontSize: 60}} />
                <Typography sx={{fontSize: 15, mb: 1}} variant="body1">Você não tem permissão para executar esta ação.</Typography>
            </Box>
        )
    }

    return (
        <Shell title="Produto">
            <Box sx={{textAlign: 'center'}}>
                <ErrorIcon color="error" sx={{fontSize: 60}} />
                <Typography sx={{fontSize: 15, mb: 1}} variant="body1">Você não tem permissão para executar esta ação.</Typography>
            </Box>
        </Shell>
    );
};