import { AuthErrorCodes, User, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import firebase from "../core/firebase";
import application from "../core/application";
import ValueObjectStateify from "../common/vo/value-object-stateify";
import { FirebaseError } from "firebase/app";
import { AppError } from "../common/app-error";

export enum AUTHENTICATION_ERROR {
    WRONG_USERNAME = 'wrong_username',
    WRONG_PASSWORD = 'wrong_password',
    UNKNOWN = 'unknown'
}

class Authentication {

    static instance = new Authentication();
    private constructor() {
    }

    async login(username: string, password: string): Promise<boolean> {
        const key = application.bussiness.get()?.key;

        try {
            const userCredential = await signInWithEmailAndPassword(firebase.auth, `${username}@${key}.simplou`, password);
            return userCredential.user !== null;
        } catch(err: any) {
            if ((err as FirebaseError).code === AuthErrorCodes.INVALID_PASSWORD) {
                throw new AppError(AUTHENTICATION_ERROR.WRONG_PASSWORD)
            }

            if ((err as FirebaseError).code === AuthErrorCodes.USER_DELETED) {
                throw new AppError(AUTHENTICATION_ERROR.WRONG_USERNAME)
            }
            
            throw new AppError(AUTHENTICATION_ERROR.UNKNOWN, err.code);
        }
    }
}

export default Authentication.instance;