import { OutlinedInput, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";
import ValueObjectObservable from "../vo/value-object-observable";

type MoneyInputValueObjectProps = {
    title: string;
    textFieldProps?: TextFieldProps
    valueObject: ValueObjectObservable<number>;
}

export const MoneyInputValueObject = ({title, valueObject, textFieldProps}: MoneyInputValueObjectProps) => { 
    const [value, setValue] = useState<number>(valueObject.get());

    useEffect(() => {
        const subscription = valueObject.addEventListener((value) => {
            setValue(value);
        });

        return () => valueObject.removeEventListener(subscription);;
    }, [valueObject]);

    const getMoneyDisplay = (money: number) => {
        const valueDisplay = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(money).replaceAll("[^0123456789.,]","");
        return valueDisplay;
    }

    return (
        <TextField
            label={title}
            {...textFieldProps || {}}	

            value={getMoneyDisplay(value)}
            onChange={(event) => {
                let inputValue = event.target.value.replace(/[^0-9]/g, '');
                const money = parseFloat(inputValue) / 100;
                valueObject.set(money);
            }}
        />
    )
}