import { Route, Routes, useNavigate } from "react-router-dom"
import application from "../../core/application";
import React, { useEffect, useState } from "react";
import './shell.page.scss';
import { AppBar, Backdrop, Box, Color, Container, CssBaseline, Divider, Drawer, FabPropsColorOverrides, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SpeedDial, SpeedDialAction, SpeedDialIcon, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { CheckCircle, Home, Inventory, Storefront } from "@mui/icons-material";
import CategoryIcon from '@mui/icons-material/Category';
import { Permission } from "../../users/user.entity";
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

const drawerWidth = 240;



type ShellProps = {
  title: string;
  children: JSX.Element,
  speedDial?: Array<{
    title: string,
    icon: JSX.Element,
    color: any, //Is internal interface
    tooltipOpen?: boolean,
    onClick: () => void
  }>
  speedDialOnlySize?: number 
}

export const Shell = (props: ShellProps) => {
    const navigate = useNavigate();
    const [user] = application.user.useState();
    const [mobileOpen, setMobileOpen] = useState(false);

    const [speedDialOpened, setSpeedDialOpened] = useState(false);

    const theme = useTheme();
    const displaySpeedDial = useMediaQuery(theme.breakpoints.down(props.speedDialOnlySize || 0));


    useEffect(() => {
        if (!user) navigate('/login');
    }, [navigate, user]);

  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (
      <div>
        <Toolbar />
        <Divider />
        <List>
          <ListItem key={'Inicio'} disablePadding>
            <ListItemButton onClick={() => navigate('/')}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={'Inicio'} />
            </ListItemButton>
          </ListItem>

          {user?.hasPermission(Permission.SELL) && (
            <ListItem key={'Vender'} disablePadding>
              <ListItemButton onClick={() => navigate('/vender')}>
                <ListItemIcon>
                  <Storefront />
                </ListItemIcon>
                <ListItemText primary={'Vender'} />
              </ListItemButton>
            </ListItem>
          )}

          {user?.hasPermission([Permission.SELL, Permission.WRITE_PRODUCTS]) && (
            <ListItem key={'Produtos'} disablePadding>
              <ListItemButton onClick={() => navigate('/produtos')}>
                <ListItemIcon>
                  <Inventory />
                </ListItemIcon>
                <ListItemText primary={'Produtos'} />
              </ListItemButton>
            </ListItem>
          )}

          {user?.hasPermission([Permission.SELL, Permission.WRITE_PRODUCTS]) && (
            <ListItem key={'Produtos de Produtos'} disablePadding>
              <ListItemButton onClick={() => navigate('/produtos/categorias')}>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary={'Categoria de Produtos'} />
              </ListItemButton>
            </ListItem>
          )}

          {user?.hasPermission([Permission.SELL, Permission.WRITE_PRODUCTS]) && (
            <ListItem key={'Fornecedores'} disablePadding>
              <ListItemButton onClick={() => navigate('/produtos/fornecedores')}>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={'Fornecedores'} />
              </ListItemButton>
            </ListItem>
          )}

          {user?.hasPermission([Permission.READ_WRITE_USERS]) && (
            <ListItem key={'Usuários'} disablePadding>
              <ListItemButton onClick={() => navigate('/usuarios')}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={'Usuários'} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </div>
    );
  
    const container = window !== undefined ? () => window.document.body : undefined;
  
    return (
      <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, 
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: 900 },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: 900 },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
            {props.children}
        </Box>

        {props.speedDial && (!props.speedDialOnlySize || (props.speedDialOnlySize && displaySpeedDial)) ? (
          <>
          <Backdrop open={speedDialOpened} sx={(theme) => ({zIndex: 999999})} />
          <SpeedDial
              ariaLabel="Ações"
              sx={(theme) => ({ position: 'fixed', bottom: 16, right: 16, zIndex: 999999})}
              icon={<SpeedDialIcon />}
              onClose={() => setSpeedDialOpened(false)}
              onOpen={() => setSpeedDialOpened(true)}
              open={speedDialOpened}
          >
            {props.speedDial.map((dial) => (
              <SpeedDialAction
                icon={dial.icon}
                tooltipTitle={dial.title}
                FabProps={{
                  color: dial.color
                }}
                tooltipOpen={dial.tooltipOpen || false}
                onClick={() => { setSpeedDialOpened(false); dial.onClick() }}
            />
            ))}
          </SpeedDial>
          </>
        ) : null}
        
      </Box>
      </>
    );
}