import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import './ui.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, RouterProvider, Routes, redirect } from 'react-router-dom';
import application from './core/application';
import { AppError } from './common/app-error';
import { BussinessQueryErrorKey } from './bussiness/bussiness.query';
import { LoginPage } from './authentication/login.page';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SellPage } from './sell/pages/sell.page';
import { sellRepository } from './sell/sell.repository';
import { Sell } from './sell/sell.entity';
import { v4 } from 'uuid';
import EntityListObservable from './common/entity-list-observable';
import Swal from 'sweetalert2';
import { App } from './app';
import moment from 'moment';
import 'moment/locale/pt-br';
import { handleErrors } from './common/error-handler';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

moment.locale('pt-br');
handleErrors();

const initialize = async() => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

initialize();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
