import serializable from "../common/decorators/serializable";
import { EntityFirebase } from "../common/entity.firebase";

@serializable
export class ProductCategory extends EntityFirebase {
    constructor(
        public readonly id: string,
        public title: string,
        public description: string
    ) {
        super();
    }

    static async build(id: string, data: any) {
        return new ProductCategory(id, data.title, data.description);
    }
}