import { Button, Container, Form, Input, Message } from "semantic-ui-react";
import './login.page.scss';
import application from "../core/application";
import { FormEvent, useEffect, useState } from "react";
import authenticationService, { AUTHENTICATION_ERROR } from "./authentication.service";
import { AppError } from "../common/app-error";
import { useNavigate } from "react-router-dom";

type FormError = {
    error: string|null,
    username: string|null,
    password: string|null
} 

const emptyError = {error: null, username: null, password: null};

export const LoginPage = () => {
    const [error, setError] = useState<FormError>(emptyError);
    const [loading, setLoading] = useState<boolean>(false);
    const [user] = application.user.useState();
    const navigate = useNavigate();


    useEffect(() => {
        if (user) return navigate('/');
    }, [user, navigate]);


    const login = async(event: FormEvent<HTMLFormElement>) => {
        setError(emptyError);
        setLoading(true);
        const formData = new FormData(event.target as HTMLFormElement);

        const username = formData.get('username')?.toString();
        const password = formData.get('password')?.toString();

        if (!username || !password) {
            setError(error => ({
                ...error,
                username: !username ? 'Informe seu usuário' : null,
                password: !password ? 'Infrome sua senha' : null
            }))
            setLoading(false);
            return;
        }

        try {
            const success = await authenticationService.login(username, password);
            if (!success) {
                setError(error => ({...error, error: 'Não foi possível efetuar o login, erro desconhecido.'})); 
                return;  
            }

            navigate('/');
        } catch(err) {
            console.log(err);
            if ((err as AppError).key === AUTHENTICATION_ERROR.WRONG_USERNAME) {
                setError(error => ({...error, username: 'Usuário informado é inválido.', error: 'Não foi possível efetuar o login, os dados informados estão incorretos.'}));
                return;
            }

            if ((err as AppError).key === AUTHENTICATION_ERROR.WRONG_PASSWORD) {
                setError(error => ({...error, password: 'Senha informada é inválido.', error: 'Não foi possível efetuar o login, os dados informados estão incorretos.'}));
                return;
            }

            setError(error => ({...error, error: 'Não foi possível efetuar o login, ocorreu um erro desconhecido.'}));
            return;
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="brand" style={{backgroundImage: `url(${application.bussiness.get()?.image})`}}></div>

            <div className="login-form">
                <Container className="container">
                    <Form onSubmit={(event, data) => login(event)} loading={loading} error={error.error !== null}>

                        {error.error !== null ? (<Message error content={error.error} />) : null}
                        
                        <Form.Field
                            name="username"
                            required={true}
                            control={Input}
                            label="Usuário"
                            placeholder="Usuário..."
                            error={error.username}
                        />

                        <Form.Field
                            name="password"
                            required={true}
                            control={Input}
                            label="Senha"
                            type="password"
                            placeholder="Senha..."
                            error={error.password}
                        />
                        <Button type='submit' fluid color="green">Entrar</Button> 
                    </Form>
                </Container>
            </div>
        </div>
    )
};