import { Entity } from "./entity";
import ListObservable from "./list-observable";

export default class EntityListObservable<EntityType extends Entity> extends ListObservable<EntityType> {
    
    add(value: EntityType) {
        this.values.push(value);
        this.addItem.raiseEvent(value);
        this.changed.raiseEvent(this.values);
    }

    change(id: string, value: EntityType) {
        this.values = this.values.map((item) => {
            if (item.id === id) {
                return value;
            }

            return item;
        });
        this.changed.raiseEvent(this.values);
    }

    addList(list: EntityType[]) {
        list.forEach((value) => {
            this.values.push(value)
            this.addItem.raiseEvent(value);
        });

        this.changed.raiseEvent(this.values);
    }

    getById(id: string): EntityType|undefined {
        return this.values.find((value) => value.id === id);
    }

    has(id: string) {
        return this.hasWithFilter((item) => item.id === id);
    }

    removeById(id: string) {
        this.values = this.values.filter((value) => {
            return value.id !== id;
        });
        this.removeItem.raiseEvent(id);
        this.changed.raiseEvent(this.values);
    }
};