import { RepositoryFirebase } from "../common/repository.firebase";
import { User } from "./user.entity";

export class UserRepository extends RepositoryFirebase<User> {
    protected collection = 'users';
    protected entity = User;


    async create(user: User) {
        // TODO: em breve
    }

    async save(user: User) {
        if (await this.exists(user.id)) {
            return super.save(user);
        }

        await this.create(user);
        return true;
    }
}

export const userRepository = new UserRepository();