import { Timestamp } from "firebase/firestore";
import serializable, { propretyCustomSerializable } from "../common/decorators/serializable";
import EntityListObservable from "../common/entity-list-observable";
import { EntityFirebase } from "../common/entity.firebase";
import { SellProduct } from "./sell-product";
import moment, { Moment } from "moment";
import { productRepository } from "../product/product.repository";
import { Product } from "../product/product.entity";

export enum SellPaymentMethod {
    UNPAID = 'unpaid',
    MONEY = 'money',
    CREDIT_CARD_DEBIT = 'credit_card_debit',
    PIX = 'pix'
}

@serializable
export class Sell extends EntityFirebase {
    @propretyCustomSerializable((value: Moment) => Timestamp.fromDate(value.toDate()))
    private startedAt: Moment;

    @propretyCustomSerializable((value: Moment|null) => !value ? null : Timestamp.fromDate(value.toDate()))
    private completedAt: Moment|null;

    constructor(
        public readonly id: string,
        private products: EntityListObservable<SellProduct>,
        private paymentMethod: SellPaymentMethod,
        private customer: string,
        startedAt: Moment = moment(),
        completedAt: Moment|null = null,
    ) {
        super();
        this.completedAt = completedAt;
        this.startedAt = startedAt;
    }

    getProducts() {
        return this.products;
    }

    addProduct(product: SellProduct) {
        const existingProduct = this.products.getById(product.id)
        if (existingProduct) {
            existingProduct.quantity += product.quantity;
            this.products.change(product.id, existingProduct);
            return;
        }

        this.products.add(product);
    }

    getProduct(id: string) {
        return this.products.getById(id);
    }

    changeProduct(product: SellProduct) {
        this.products.change(product.id, product);
    }

    decrementAmount(id: string) {
        const product = this.products.getById(id);
        if (!product) return;

        if (product.quantity === 1) return this.products.remove(product);

        product.quantity = product.quantity-1;
        this.products.change(product.id, product);
    }

    incrementAmount(id: string) {
        const product = this.products.getById(id);
        if (!product) return;

        product.quantity = product.quantity+1;
        this.products.change(product.id, product);
    }

    removeProduct(id: string) {
        this.products.removeById(id);
    }

    getSellTotal(): number {
        return this.products.getValues().reduce((accumulator, product) => {
            return (product.price * product.quantity) + accumulator;
        }, 0);
    }

    getCustomer() {
        return this.customer;
    }

    setCustomer(customer: string) {
        this.customer = customer;
    }

    getPaymentMethod() {
        return this.paymentMethod;
    }

    setPaymentMethod(paymentMethod: SellPaymentMethod) {
        this.paymentMethod = paymentMethod;
    }

    setCompletedAt(completedAt: Moment) {
        this.completedAt = completedAt;
    }

    getCompletedAt() {
        return this.completedAt;
    }

    getStartedAt() {
        return this.startedAt;
    }

    static async build(id: string, data: any) {
        const products = await Promise.all(data['products'].map(async(sellProduct: SellProduct) => {
            try {
                return await SellProduct.build(sellProduct)
            } catch(error) {
                return null;
            }
        }));

        return new this(
            id,
            new EntityListObservable(products.filter(product => product !== null)),
            data['paymentMethod'] || SellPaymentMethod.UNPAID,
            data['customer'] || '',
            moment(data['startedAt'].toDate()),
            data['completedAt'] ? moment(data['completedAt'].toDate()) : null
        );
    }
}