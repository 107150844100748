import { useCallback, useEffect, useState } from "react";
import { Permission, User, permissionsLabel } from "../user.entity";
import { userRepository } from "../user.repository";
import { PagenedResult } from "../../common/pagened-result";
import { Edit, Delete } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton, ListItemText, IconButton, ListItemAvatar, Avatar, Typography, Button, List, ListItem, Divider, TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { Shell } from "../../common/shell/shell.page";
import { SwalReact } from "../../common/swal-react";
import { useNavigate } from "react-router-dom";
import firebase from "../../core/firebase";
import { httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import application from "../../core/application";

export const UsersPage = () => {
    const [usersPagened, setUsersPagened] = useState<PagenedResult<User>>();
    const [loading, setLoading] = useState<boolean>(true);
    const [hasNext, setHasNext] = useState<boolean>(true);
    const [hasPrevius, setHasPrevius] = useState<boolean>(true);
    const [user] = application.user.useState();

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const users = await userRepository.getAllPagened(10, 'username');
            setUsersPagened(users);
            setLoading(false);
        })();        
    }, []);

    useEffect(() => {
        (async() => {
            if (!usersPagened) return;
            setHasNext(await usersPagened.hasNext());
            setHasPrevius(await usersPagened.hasPrevius());
        })();
    }, [usersPagened]);

    const nextPage = useCallback(async() => {
        if (!usersPagened) return;
        const next = await usersPagened.next();
        setUsersPagened(next);
    }, [usersPagened]);

    const previusPage = useCallback(async() => {
        if (!usersPagened) return;
        const previus = await usersPagened.previus();
        setUsersPagened(previus);
    }, [usersPagened]);


    const editUser = async(userToEdit: User) => {
        SwalReact.fire({
            title: 'Editar usuário',
            html: (
                <form id='edit-user'>
                    <TextField
                        variant="outlined"
                        label="Nome"
                        defaultValue={userToEdit.name}
                        fullWidth
                        margin="normal"
                        name="name"
                    />

                    <FormGroup>
                        {Object.keys(permissionsLabel).map((permission: any) => (
                            <FormControlLabel control={<Checkbox name={`${permission}`} defaultChecked={userToEdit.permissions.includes(permission)} />} label={permissionsLabel[permission as Permission]} />
                        ))}
                    </FormGroup>
                </form>
            ),
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            preConfirm: async() => {
                if (!user) return;
                const form = document.getElementById('edit-user') as HTMLFormElement;
                const formData = new FormData(form);
                const data = Object.fromEntries(formData.entries());

                const { name, ...permissions } = data;

                const userPermissions = Object.keys(permissions).filter((permission) => (
                    permissions[permission] === 'on'
                )) as Permission[];

                if (user.id === userToEdit.id && !userPermissions.includes(Permission.READ_WRITE_USERS)) {
                    return SwalReact.showValidationMessage('Você não pode remover a permissão de gerenciar usuários de si mesmo.');
                }

                if (user.id !== userToEdit.id && userPermissions.includes(Permission.READ_WRITE_USERS)) {
                    const { isConfirmed } = await SwalReact.fire({
                        title: 'Atenção',
                        text: 'Você tem certeza que deseja dar permissão de gerenciar usuários para este usuário? Este usuário poderá ter todas as permissoes e dar permissão para outros usuários.',
                        showCancelButton: true,
                        icon: 'warning',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Sim',
                    });

                    if (!isConfirmed) {
                        SwalReact.fire({
                            title: 'Operação cancelada',
                            icon: 'info'
                        });
                        return;
                    }
                }


                userToEdit.name = name as string;
                userToEdit.permissions = userPermissions;

                await userRepository.save(userToEdit);
                setUsersPagened(await usersPagened?.refreshItens());
                return true;
            },
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !SwalReact.isLoading()
        });
    };

    const createUser = async() => {
        SwalReact.fire({
            title: 'Novo usuário',
            html: (
                <form id='new-user'>
                    <TextField
                        variant="outlined"
                        label="Nome"
                        fullWidth
                        margin="normal"
                        name="name"
                    />

                    <TextField
                        variant="outlined"
                        label="Username"
                        fullWidth
                        margin="normal"
                        name="username"
                    />

                    <TextField
                        variant="outlined"
                        label="Senha"
                        fullWidth
                        margin="normal"
                        name="password"
                    />

                    <FormGroup>
                        {Object.keys(permissionsLabel).map((permission: any) => (
                            <FormControlLabel control={<Checkbox name={`${permission}`} />} label={permissionsLabel[permission as Permission]} />
                        ))}
                    </FormGroup>
                </form>
            ),
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            preConfirm: async() => {
                const form = document.getElementById('new-user') as HTMLFormElement;
                const formData = new FormData(form);
                const data = Object.fromEntries(formData.entries());

                const { name, username, password, ...permissions } = data;

                if (!name || !username || !password || Object.keys(permissions).length < 1) {
                    return SwalReact.showValidationMessage('Preencha todos os campos');
                }

                if ((password as any).length < 6) {
                    return SwalReact.showValidationMessage('A senha deve ter no mínimo 6 caracteres');
                }
                
                try {
                    const userPermissions = Object.keys(permissions).filter((permission) => (
                        permissions[permission] === 'on'
                    )) as Permission[]

                    if (userPermissions.includes(Permission.READ_WRITE_USERS)) {
                        const { isConfirmed } = await SwalReact.fire({
                            title: 'Atenção',
                            text: 'Você tem certeza que deseja criar este usuario com permissão de gerenciar usuários? Este usuário poderá ter todas as permissoes e dar permissão para outros usuários.',
                            showCancelButton: true,
                            icon: 'warning',
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Sim',
                        });
    
                        if (!isConfirmed) {
                            SwalReact.fire({
                                title: 'Operação cancelada',
                                icon: 'info'
                            });
                            return;
                        }
                    }

                    const result: any = await httpsCallable(firebase.functions, 'createUser')({
                        name,
                        username,
                        password,
                        permissions: userPermissions,
                    });

                    if (result.data.code !== 200) {
                        if (result.data.error === 'The email address is already in use by another account.') {
                            if (!SwalReact.isVisible()) {
                                SwalReact.fire({
                                    title: 'Já existe um usuário com este username.',
                                    icon: 'error'
                                });
                                return;
                            }

                            return SwalReact.showValidationMessage('Já existe um usuário com este username.');
                        }

                        SwalReact.fire({
                            title: 'Ocorreu um erro, tente novamente mais tarde.',
                            icon: 'error'
                        });
                    }
                } catch (error: any) {
                    return SwalReact.fire({
                        title: 'Ocorreu um erro, tente novamente mais tarde.',
                        icon: 'error'
                    });
                }

                setUsersPagened(await usersPagened?.refreshItens());
                return true;
            },
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !SwalReact.isLoading()
        });
    };

    const removeUser = async(userToRemove: User) => {
        if (!user) return;
        if (user.id === userToRemove.id) {
            SwalReact.fire({
                title: 'Remover usuário',
                text: 'Você não pode remover seu próprio usuário',
                icon: 'error'
            });
            return;
        }

        await SwalReact.fire({
            title: 'Remover usuário',
            text: `Tem certeza que deseja remover o usuário ${userToRemove.name}?`,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            preConfirm: async() => {
                try {
                    const result: any = await httpsCallable(firebase.functions, 'removeUser')({
                        uid: userToRemove.id
                    });

                    if (result.data.code !== 200) {
                        console.error(result.data.error);
                        return SwalReact.showValidationMessage('Ocorreu um erro ao remover usuário, tente novamente mais tarde');
                    }
                } catch (error: any) {
                    console.error(error);
                    return SwalReact.showValidationMessage('Ocorreu um erro ao remover o usuário, tente novamente mais tarde');
                }

                setUsersPagened(await usersPagened?.refreshItens());
                return true;
            },
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !SwalReact.isLoading()
        });
    };

    return (
        <Shell title="Usuários">
            <div className="products-page">
                <Button fullWidth color='info' variant="contained" onClick={() => createUser()}>Adicionar usuaro</Button>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem component="a" href="#customized-list">
                        <ListItemText
                            sx={{ my: 0 }}
                            primary="Usuários"
                            primaryTypographyProps={{
                                fontSize: 20,
                                fontWeight: 'medium',
                                letterSpacing: 0,
                            }}
                        />
                    </ListItem>
                    {usersPagened?.itens.map(user => (
                        <>
                        <Divider />
                        <ListItem key={user.id} secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="Editar" onClick={() => editUser(user)}>
                                    <Edit />
                                </IconButton>

                                <IconButton edge="end" aria-label="Remover" onClick={() => removeUser(user)}>
                                    <Delete />
                                </IconButton>
                            </>
                        }>
                            <ListItemText primary={
                                <Typography
                                    component="p"
                                    variant="subtitle1"
                                    sx={{fontWeight: 'bold', fontSize: 16}}
                                    color="info"
                                >
                                    {user.username}
                                </Typography>
                            } secondary={
                                <>
                                    <Typography
                                        component="p"
                                        variant="subtitle2"
                                        color="text.primary"
                                    >
                                        {user.name}
                                    </Typography>
                                    <Typography
                                        component="p"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Permissões: {user.permissions.map((permission) => (permissionsLabel[permission])).join(', ')}
                                    </Typography>
                                    
                                </>
                            } />
                        </ListItem>
                        </>
                    ))}
                </List>

                <Button fullWidth color='info' variant="outlined" disabled={hasPrevius} onClick={() => previusPage()}>{'< Pagina anterior'}</Button>
                <Button fullWidth color='info' variant="outlined" sx={{mt: 2}} disabled={hasNext} onClick={() => nextPage()}>{'Proxima pagina >'}</Button>
            </div>
        </Shell>
    );
};