import { BrowserRouter, Route, Router, RouterProvider, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import application from "./core/application";
import Swal from "sweetalert2";
import firebase from "./core/firebase";
import { LoginPage } from "./authentication/login.page";
import { SellPage } from "./sell/pages/sell.page";
import { ProductsPage } from "./product/pages/products.page";
import { ProductFormPage } from "./product/pages/product-form.page";
import { ProductCategoriesPage } from "./product/pages/product-categories.page";
import { ProductsupplierPage } from "./product/pages/product-supplier.page";
import { UsersPage } from "./users/pages/users.page";
import { userRepository } from "./users/user.repository";
import { NotPermissionPage } from "./users/pages/not-permission.page";
import { DashboardPage } from "./dashboard/pages/dashboard.page";
import { Backdrop, CircularProgress } from "@mui/material";
import { ProductQrcodePage } from "./product/pages/product-qrcode.page";

export const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async() => {
            try {
                await application.loadBussiness();
            } catch(err) {
                console.error(err);
                await Swal.fire({
                    title: 'Erro',
                    text: 'Não foi possível carregar a sua aplicação.',
                    icon: 'error',
                    showCloseButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
            }

            await (new Promise((resolve, reject) => {
                application.authReady.addEventListener((ready) => {
                    if (ready) return resolve(null);
                });

                if (application.authReady.get()) {
                    resolve(true);
                    return;
                }

                // timeout
                setTimeout(() => resolve(null), 5000);
            }));
                

            application.initializePrint();
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return (
            <Backdrop
            sx={{ color: '#fff', zIndex: 999 }}
            open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" Component={LoginPage} />
                <Route path="/vender" Component={SellPage} />
                <Route path="/produtos" Component={ProductsPage} />
                <Route path="/produtos/novo" Component={ProductFormPage} />
                <Route path="/produtos/categorias" Component={ProductCategoriesPage} />
                <Route path="/produtos/fornecedores" Component={ProductsupplierPage} />
                <Route path="/produtos/editar/:productId" Component={ProductFormPage} />
                <Route path="/produtos/qrcode/:qrcodeId/:token" Component={ProductQrcodePage} />
                <Route path="/usuarios" Component={UsersPage} />
                <Route path="/403" Component={NotPermissionPage} />
                <Route path="/" Component={DashboardPage} />
            </Routes>
        </BrowserRouter>
    );
};