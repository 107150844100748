import { Avatar, Button, ButtonBase, Chip, Container, Grid, Paper, TextField, Typography } from "@mui/material"

import './sell.page.scss';
import { Shell } from "../../common/shell/shell.page";
import { useCallback, useEffect, useState } from "react";
import { Sell, SellPaymentMethod } from "../sell.entity";
import EntityListObservable from "../../common/entity-list-observable";
import { v4 } from "uuid";
import { sellRepository } from "../sell.repository";
import { useLocalStorageState } from "../../common/hooks/localstorage-state";
import { SellComponent } from "../components/sell-component";
import { FinishSellComponent } from "../components/finish-sell.component";
import { grey } from "@mui/material/colors";
import { SwalReact } from "../../common/swal-react";
import Moment from "react-moment";
import 'moment/locale/pt-br';
import application from "../../core/application";
import { Permission } from "../../users/user.entity";

export const SellPage = () => {
    const [loading, setLoading] = useState(true);

    const [sellId, setSellId] = useLocalStorageState<string>('sell_id');
    const [unpaidSells] = sellRepository.useAllUnpaidState();

    application.useHasPermission(Permission.SELL);

    const registerSell = useCallback(async() => {
        setLoading(true);
        const newSell = new Sell(v4(), new EntityListObservable(), SellPaymentMethod.UNPAID, '');
        await sellRepository.save(newSell);
        setSellId(newSell.id);
        setLoading(false);
    }, [setSellId]);

    const closeSell = useCallback(() => {
        setSellId(null);
    }, [setSellId])

    useEffect(() => {
        const keyEvent = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (sellId) return;
                registerSell();
                return;
            }
        };

        document.addEventListener('keydown', keyEvent);

        return () => document.removeEventListener('keydown', keyEvent);
    }, [registerSell, sellId]);

    if (!sellId) {
        return (
            <Shell title="Vender">
                <div style={{textAlign: 'center'}}>
                    <Button fullWidth variant="contained" color='success' onClick={() => registerSell()}>Iniciar nova venda <Chip label="Enter" sx={{bgcolor: grey[500]}} /></Button>

                    <br /><br />

                    {unpaidSells.sort((a: any, b: any) => b.getStartedAt() - a.getStartedAt()).filter((sell) => sell.getCustomer() !== '').map((sell) => (
                        <>
                        <Button
                            color='info'
                            fullWidth
                            onClick={() => setSellId(sell.id)}
                            variant="outlined"
                            key={sell.id}
                            sx={{mt: 2}}
                        >
                            {sell.getCustomer() === '' ? 'Cliente não informado' : sell.getCustomer()} - {sell.getSellTotal().toLocaleString('pt-BR', {
                                currency: 'BRL',
                                style: 'currency'
                            })} - &nbsp; <Moment date={sell.getStartedAt()} fromNow locale='pt-br' />
                        </Button>
                        </>
                    ))}
                </div>
            </Shell>
        );
    }

    return (
        <SellComponent sellId={sellId} closeSell={() => {
            setSellId(null);
        }} />
    )
}