import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { EntityFirebase } from "../common/entity.firebase";
import { RepositoryFirebase } from "../common/repository.firebase";
import { Product } from "./product.entity";
import { PagenedResult } from "../common/pagened-result";

class ProductRepository extends RepositoryFirebase<Product> {
    protected entity = Product;
    protected collection = 'products';

    async findByName(prefix: string, limitTo: number = 10): Promise<Product[]> {
        const ref = this.getCollection();
        const q = query(ref, where("name", ">=", prefix), where("name", "<=", `${prefix}\uf8ff`), limit(limitTo));

        const querySnapshot = await getDocs(q);
        return await Promise.all(querySnapshot.docs.map(async(snap) => {
            return await Product.build(snap.id, snap.data());
        }));
    }

    async getByCategoryId(categoryId: string, limitTo: number = 10) {
        const ref = this.getCollection();
        const q = query(ref, where("category", "==", categoryId), limit(limitTo));

        const querySnapshot = await getDocs(q);
        return await Promise.all(querySnapshot.docs.map(async(snap) => {
            return await Product.build(snap.id, snap.data());
        }));

    }

    public async getByCategoryIdPagened(categoryId: string, limitTo: number = 10) {
        const ref = this.getCollection();
        const querySnap = query(ref, orderBy('category'), limit(limitTo), where("category", "==", categoryId));
        const snapshot = await getDocs(querySnap);

        const items = await Promise.all(snapshot.docs.map(async(item) => {
            return await (this.entity as any).build(item.id, item.data());
        }));

        return new PagenedResult<Product>(
            items,
            snapshot.docs[0],
            snapshot.docs[snapshot.docs.length - 1],
            querySnap,
            limitTo,
            this.entity
        );
    }

    public async getBySupplierIdPagened(supplier: string, limitTo: number = 10) {
        const ref = this.getCollection();
        const querySnap = query(ref, orderBy('supplier'), limit(limitTo), where("supplier", "==", supplier));
        const snapshot = await getDocs(querySnap);

        const items = await Promise.all(snapshot.docs.map(async(item) => {
            return await (this.entity as any).build(item.id, item.data());
        }));

        return new PagenedResult<Product>(
            items,
            snapshot.docs[0],
            snapshot.docs[snapshot.docs.length - 1],
            querySnap,
            limitTo,
            this.entity
        );
    }
}

export const productRepository = new ProductRepository();