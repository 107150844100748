import { QuerySnapshot, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { RepositoryFirebase } from "../common/repository.firebase";
import { Sell, SellPaymentMethod } from "./sell.entity";
import { useEffect, useState } from "react";

class SellRepository extends RepositoryFirebase<Sell> {
    static readonly instance = new SellRepository();

    protected collection = 'sells';
    protected entity = Sell;

    private getAllUnpaidQuery() {
        const ref = this.getCollection();
        return query(ref, where("paymentMethod", "==", SellPaymentMethod.UNPAID));
    }

    async getAllUnpaid() {
        const qSnapshot = await getDocs(this.getAllUnpaidQuery());

        return await Promise.all(qSnapshot.docs.map(async(sell) => {
            return await Sell.build(sell.id, sell.data());
        }));
    }

    useAllUnpaidState() {
        return this.useSubscribeQuery(this.getAllUnpaidQuery());
    }
}

export const sellRepository = SellRepository.instance;