import { OutlinedInput, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";

type MoneyChanged = {
    value: number;
    display: string;
}

type MoneyInputProps = {
    onChange: (changed: MoneyChanged) => void;
    title: string;
    value: number;
    textFieldProps?: TextFieldProps
    useValueObejct?: boolean;
}

export const MoneyInput = ({onChange, title, value, textFieldProps}: MoneyInputProps) => {    
    const getMoneyDisplay = (money: number) => {
        const valueDisplay = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(money).replaceAll("[^0123456789.,]","");
        return valueDisplay;
    }

    return (
        <TextField
            label={title}
            {...textFieldProps || {}}	

            value={getMoneyDisplay(value)}
            onChange={(event) => {
                let inputValue = event.target.value.replace(/[^0-9]/g, '');
                const money = parseFloat(inputValue) / 100;
                onChange({value: money, display: getMoneyDisplay(value)});
            }}
        />
    )
}