import { Sell } from "@mui/icons-material";
import serializable, { Serializable, propretyCustomSerializable } from "../common/decorators/serializable";
import { Product, noImage } from "../product/product.entity";
import { SellUnregisterProduct } from "./sell-unregister-product";
import { productRepository } from "../product/product.repository";
import moment, { Moment } from "moment";
import { v4 } from "uuid";
import { Timestamp } from "firebase/firestore";

@serializable
export class SellProduct extends Serializable {
    public readonly id: string;

    @propretyCustomSerializable((value: Moment) => !value ? null : Timestamp.fromDate(value.toDate()))
    private addedAt: Moment;

    @propretyCustomSerializable((entity: Product) => {
        if (entity instanceof SellUnregisterProduct) {
            const object = entity.toObject();
            return object;
        }

        return {
            id: entity.id
        }
    })
    public readonly entity: Product;

    public price: number; 

    constructor(
        entity: Product,
        public quantity: number,
        price?: number,
        addedAt?: Moment
    ) {
        super();
        this.id = entity.id;
        this.entity = entity;
        this.price = price ?? entity.getPrice();
        this.addedAt = addedAt ?? moment();
    }
    
    static async build(data: any) {
        // retrocompatibilidade
        if (typeof data.entity === 'string') {
            data.entity = {
                id: data.entity
            }
        }

        if (!data.entity) {
            data.entity = {
                id: data.id
            }
        }

        let product;

        if (data.entity.unregister) {
            const {id, ...productData} = data.entity;
            product = await SellUnregisterProduct.build(data.entity.id || v4(), productData);
        } else {
            product = await productRepository.findByIdOrNull(data.entity.id);
        }
        
        if (!product) {
            product = new SellUnregisterProduct(data.entity.id || v4(), 'Produto desconhecido', data.price, noImage);
        }

        return new this(
            product,
            data.quantity,
            data.price,
            data.addedAt
        )
    }
}