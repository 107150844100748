import { CollectionReference, DocumentData, doc, getDoc, setDoc } from "firebase/firestore";
import serializable, { Serializable } from "./decorators/serializable";
import { Entity } from "./entity";

export abstract class EntityFirebase implements Entity {
    abstract readonly id: string;
    
    static build(id: string, data: any): Promise<EntityFirebase> {
        throw new Error('Build não foi implementando.');
    }

    toObject(): any {
        throw new Error("Colocou this ao inves da classe no new do .build? Adicionou @serializable na entidade?");
    }
}