import serializable from "../common/decorators/serializable";
import { EntityFirebase } from "../common/entity.firebase";

@serializable
export class ProductImageQRCode extends EntityFirebase {
    constructor(
        public readonly id: string,
        public readonly productId: string,
        public readonly token: string,
        public usaged: boolean = false,
    ) {
        super();
    }

    static async build(id: string, data: any) {
        return new this(
            id,
            data.productId,
            data.token,
            data.usaged,
        );
    }

}