import { useEffect, useState } from "react";
import { ProductSupplier } from "../product-supplier.entity";
import { productSupplierRepository } from "../product-supplier.repository";
import { Backdrop, CircularProgress, Divider, IconButton, ListItem, ListItemText, List, Button, TextField } from "@mui/material";
import { Shell } from "../../common/shell/shell.page";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { SwalReact } from "../../common/swal-react";
import { v4 } from "uuid";
import { Permission } from "../../users/user.entity";
import application from "../../core/application";

export const ProductsupplierPage = () => {
    const [supplier, setSupplier] = useState<ProductSupplier[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [user] = application.user.useState();

    application.useHasPermission([Permission.SELL, Permission.WRITE_PRODUCTS])

    useEffect(() => {
        (async() => {
            const supplier = await productSupplierRepository.getAll();
            setSupplier(supplier);
            setLoading(false);
        })();
    }, []);

    const removeSupplier = async(supplier: ProductSupplier) => {
        const result = await Swal.fire({
            title: `Deseja realmente excluir este fornecedor ${supplier.title}?`,
            confirmButtonText: `Sim`,
            icon: 'warning',
            confirmButtonColor: '#dc3545',
            cancelButtonText: `Não`,
            showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        await productSupplierRepository.remove(supplier);
        setSupplier(items => items.filter((s) => s.id !== supplier.id));
    };

    const addsupplier = async() => {
        await SwalReact.fire({
            title: 'Adicionar Fornecedor',
            html: (
                <TextField id="newsupplier" fullWidth sx={{mt: 2}} variant="outlined" label='Nome do fornecedor' />
            ),
            showCancelButton: true,
            confirmButtonText: `Adicionar`,
            cancelButtonText: `Cancelar`,
            icon: 'info',
            preConfirm: async() => {
                const input = document.getElementById('newsupplier') as HTMLInputElement;
                if (!input) {
                    return;
                }

                if (input.value.trim() === '') {
                    SwalReact.showValidationMessage('Você precisa informar o nome do fornecedor.');
                    return;
                }

                const supplier = new ProductSupplier(v4(), input.value);
                productSupplierRepository.save(supplier);
                setSupplier(await productSupplierRepository.getAll());
            },
            focusConfirm: false,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !SwalReact.isLoading()
        });
    }

    if (loading) {
        return (
            <>
                <Backdrop
                    open={true}
                    sx={{ color: '#fff'}}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        )
    }

    return (
        <Shell title="Fornecedores">
            <div>
                {user?.hasPermission([Permission.WRITE_PRODUCTS]) && (
                    <Button fullWidth color='info' variant='contained' onClick={() => addsupplier()}>Adicionar Fornecedor</Button>
                )}
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem component="a" href="#">
                        <ListItemText
                            sx={{ my: 0 }}
                            primary="Fornecedores"
                            primaryTypographyProps={{
                                fontSize: 20,
                                fontWeight: 'medium',
                                letterSpacing: 0,
                            }}
                        />
                    </ListItem>
                    {supplier?.map(supplier => (
                        <>
                            <Divider />
                            <ListItem key={supplier.id} secondaryAction={
                                <>
                                    <IconButton edge="end" aria-label="Remover" onClick={() => removeSupplier(supplier)}>
                                        <Delete />
                                    </IconButton>
                                </>
                            }>
                                <ListItemText primary={supplier.title} />
                            </ListItem>
                        </>
                    ))}
                </List>
            </div>
        </Shell>
    );
};