import { useEffect, useState } from "react";
import { Entity } from "./entity";
import { AppEvent } from "./app-event";

export const useListObservableState = <T extends Entity>(event: AppEvent<T[]>, initialValue: T[]) => {
    const [list, setList] = useState(initialValue);

    useEffect(() => {
        const listener = event.addListener((eventList) => {
            setList([...eventList]);
        });

        return () => event.removeListener(listener);
    }, [event]);
    
    return [list];
};

export default class ListObservable<T> {
    protected values: T[];
    
    public addItem = new AppEvent<T>();
    public removeItem = new AppEvent<any>();
    public changed = new AppEvent<T[]>();

    constructor(initialValues: T[] = []) {
        this.values = initialValues;
        this.changed.raiseEvent(this.values);
    }

    add(value: T) {
        this.values.push(value);
        this.addItem.raiseEvent(value);
        this.changed.raiseEvent(this.values);
    }

    get(item: T): T|undefined {
        return this.values.find((value) => value === item);
    }

    find(callback: (value: T) => boolean): T|undefined {
        return this.values.find(callback);
    }

    getFirstByFilter(callback: (value: T) => boolean): T|undefined {
        return this.values.find(callback);
    }

    remove(item: T) {
        this.values = this.values.filter((value) => {
            return value !== item;
        });
        this.removeItem.raiseEvent(item);
        this.changed.raiseEvent(this.values);
    }

    filter(callback: (value: T) => boolean) {
        return this.values.filter(callback);
    }

    hasWithFilter(callback: (value: T) => boolean) {
        return this.filter(callback).length > 0;
    }

    getValues() {
        return this.values;
    }

    getLast() {
        return this.values.at(-1);
    }

    clean() {
        this.values = [];
        this.changed.raiseEvent(this.values);
    }

    toObject() {
        return this.values;
    }
};