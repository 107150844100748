import { Sell, SellPaymentMethod } from "../sell.entity";

import './finish-sell.component.scss';
import { Button, Chip, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import { sellRepository } from "../sell.repository";
import moment from "moment";
import application from "../../core/application";
import { productRepository } from "../../product/product.repository";
import { SellUnregisterProduct } from "../sell-unregister-product";

type FinishSellComponentProps = {
    sell: Sell,
    onCompleteSell: () => void,
    onClose: () => void,
    closeSell: () => void,
}

const sellPaymentMethods = [
    {
        title: 'Dinheiro',
        method: SellPaymentMethod.MONEY
    },
    {
        title: 'Cartão de Crédito/Debito',
        method: SellPaymentMethod.CREDIT_CARD_DEBIT
    },
    {
        title: 'PIX',
        method: SellPaymentMethod.PIX
    }
]

export const FinishSellComponent = ({sell, onCompleteSell, onClose, closeSell}: FinishSellComponentProps) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    const [moneyInput, setMoneyInput] = useState(false);
    const [money, setMoney] = useState(0.0);
    const [moneyDisplay, setMoneyDisplay] = useState('');
    const [change, setChange] = useState<number|null>(null);
    const [completed, setCompleted] = useState(false);

    const finishSell = async() => {
        sell.setCompletedAt(moment());

        sell.getProducts().getValues().forEach((product) => {
            if (product.entity instanceof SellUnregisterProduct) return;

            product.entity.decrementStock(product.quantity);
            productRepository.save(product.entity);
        });

        sellRepository.save(sell);
        setCompleted(true);
    };

    const confirmMethod = useCallback(async(index?: number) => {
        const method = sellPaymentMethods[index ?? selectedPaymentMethod].method;
        if (method === SellPaymentMethod.MONEY) {
            setMoneyInput(true);
            return;
        }

        sell.setPaymentMethod(method);
        await finishSell();
    }, [selectedPaymentMethod, sell, setCompleted]);

    const confirmMoney = useCallback(async() => {
        sell.setPaymentMethod(SellPaymentMethod.MONEY);
        
        if (money > 0) {
            setChange(money - sell.getSellTotal());
        }

        finishSell();
        setCompleted(true);
    }, [money, sell]);

    const print = useCallback(async() => {
        const printer = application.getPrint();

        const data = [
            '\x1B' + '\x40',          
            '\x1B' + '\x61' + '\x31',
            '\x1B' + '\x45' + '\x0D',
            `${application.bussiness.get()?.name}` + '\x0A',
            '\x1B' + '\x45' + '\x0A',
            `${sell.getCompletedAt()?.format('LLL')}` + '\x0A',
            '\x0A',
            `Recibo #${sell.id}` + '\x0A',
            '\x0A',
            '\x1B' + '\x61' + '\x30',
            '\x0A' + '-------------------' + '\x0A',          
        ];

        sell.getProducts().getValues().forEach((product) => {
            data.push(`${product.entity.getName()}` + '\x0A');
            data.push(`${product.quantity}x ${(parseFloat(product.price.toString())).toLocaleString('pt-br', {
                currency: 'BRL',
                style: 'currency'
            })}\x0A\x1B\x61\x32${(product.price * product.quantity).toLocaleString('pt-br', {
                currency: 'BRL',
                style: 'currency'
            })}` + '\x0A\x1B\x61\x30')
        });

        data.push(
            '\x0A' + '-------------------' + '\x0A',
        );
        data.push(`Total: ${sell.getSellTotal().toLocaleString('pt-br', {
            currency: 'BRL',
            style: 'currency'
        })}` + '\x0A');

        if (sell.getPaymentMethod() === SellPaymentMethod.MONEY) {
            if (money) {
                data.push(`Valor em Dinheiro: ${money.toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency'
                })}` + '\x0A');

                data.push(`Troco: ${(money - sell.getSellTotal()).toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency'
                })}` + '\x0A');
            }
        }

        let titlePaymentMethod;
        switch(sell.getPaymentMethod()) {
            case SellPaymentMethod.CREDIT_CARD_DEBIT:
                titlePaymentMethod = 'Pago com cartão';
                break;

            case SellPaymentMethod.MONEY:
                titlePaymentMethod = 'Pago com dinheiro';
                break;

            case SellPaymentMethod.PIX:
                    titlePaymentMethod = 'Pago com pix';
                    break;

            default:
                titlePaymentMethod = 'Pago.';
                break;
        }
        data.push(titlePaymentMethod + '\x0A\x0A\x0A');

        const qr = sell.id;
        const dots = '\x09';
        const qrLength = qr.length + 3;
        const size1 =  String.fromCharCode(qrLength % 256);
        const size0 = String.fromCharCode(Math.floor(qrLength / 256));
        data.push(
            '\x1B' + '\x61' + '\x31',
            // <!-- BEGIN QR DATA -->
            '\x1D' + '\x28' + '\x6B' + '\x04' + '\x00' + '\x31' + '\x41' + '\x32' + '\x00',    // <Function 165> select the model (model 2 is widely supported)
            '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x43' + dots,               // <Function 167> set the size of the module
            '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x45' + '\x30',             // <Function 169> select level of error correction (48,49,50,51) printer-dependent
            '\x1D' + '\x28' + '\x6B' + size1 + size0 + '\x31' + '\x50' + '\x30' + qr,          // <Function 080> send your data (testing 123) to the image storage area in the printer
            '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x51' +'\x30',              // <Function 081> print the symbol data in the symbol storage area
            '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x52' +'\x30',              // <Function 082> Transmit the size information of the symbol data in the symbol storage area
            // <!-- END QR DATA -->
            '\x1B' + '\x61' + '\x30'
        )

        data.push(
            '\x1B' + '\x45' + '\x0D',
            'Este é somente um recibo, não tem valor fiscal.',
            '\x1B' + '\x45' + '\x0A',
        );

        data.push(
            '\x0A' + '\x0A' + '\x0A' + '\x0A',
            '\x1B' + '\x69'
        );

        await printer.print(data);
    }, [money, sell]); 

    useEffect(() => {
        const keyEvent = (event: KeyboardEvent) => {
            event.stopPropagation();
            event.stopImmediatePropagation();
            if (event.key === 'ArrowUp') {
                setSelectedPaymentMethod(selected => {
                    if (selected === 0) {
                        return sellPaymentMethods.length-1
                    }

                    return selected-1;
                });
                return;
            }

            if (event.key === 'ArrowDown') {
                setSelectedPaymentMethod(selected => {
                    if (selected === sellPaymentMethods.length-1) {
                        return 0;
                    }
    
                    return selected+1;
                });
                return;
            }

            if (event.key === 'Enter') {
                if (completed) {
                    print();
                    closeSell();
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                }

                if (moneyInput) {
                    confirmMoney();
                    return;
                }

                confirmMethod();
                return;
            }

            if (event.key === 'Escape') {
                if (completed) {
                    closeSell();
                }
            }
        };

        document.addEventListener("keyup", keyEvent);

        return () => document.removeEventListener("keyup", keyEvent);
    }, [change, confirmMethod, confirmMoney, onCompleteSell, moneyInput, completed, print]);

    if (completed) {
        return (
            <div className="finish-sell money">
                <h1 className="title">Venda foi concluida com sucesso!</h1>
                {change ? (
                    <>
                    <p><strong>Dinheiro pago</strong>: {moneyDisplay}</p>
                    <p><strong>Troco</strong>: {change.toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency'
                    })}</p>
                    </>
                ) : null}

                <Button
                    fullWidth
                    variant='contained'
                    color="info"
                    onClick={() => {print(); closeSell()}}><Chip label='Enter' sx={{bgcolor: grey[500]}} />Imprimir Recibo</Button>
                
                <Button
                    fullWidth
                    variant='contained'
                    color="success"
                    onClick={() => 
                        closeSell()
                    }><Chip label='Esc' sx={{bgcolor: grey[500]}} />Concluido</Button>
            </div>
        );
    }


    if (moneyInput) {
        return (
            <div className="finish-sell money">
                <h1 className="title">Qual valor foi pago em dinheiro?</h1>

                <FormControl fullWidth>
                    <InputLabel htmlFor="amount">Preço</InputLabel>
                    <OutlinedInput
                        id="money"
                        label="Amount"
                        value={moneyDisplay}
                        onBlur={(event) => event.target.focus()}
                        autoFocus={true}
                        onChange={(event) => {
                            let value = event.target.value.replace(/[^0-9]/g, '');
                            setMoney(parseFloat(value) / 100);
                            value = new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            }).format(Number(value) / 100).replaceAll("[^0123456789.,]","");
                            setMoneyDisplay(value);
                        }}  
                    />
                </FormControl>

                <Button
                    fullWidth
                    variant='contained'
                    color="success"
                    onClick={() => 
                        confirmMoney()
                    }><Chip label='Enter' sx={{bgcolor: grey[500]}} />Confirmar</Button>
                <Button
                fullWidth
                variant='contained'
                color="error"
                onClick={() => 
                    onClose()
                }>Voltar venda</Button>
            </div>
        );
    }

    return (
        <div className="finish-sell">
            <h1 className="title">Método de pagamento</h1>

            <div className="methods">
                {sellPaymentMethods.map((method, index) => (
                    <p
                        className={selectedPaymentMethod === index ? 'selected' : ''}
                        key={index}
                        onClick={() => {confirmMethod(index);}}
                    >{method.title}</p>
                ))}
            </div>

            <Button
                fullWidth
                variant='contained'
                color="success"
                onClick={() => 
                    confirmMethod()
                }><Chip label='Enter' sx={{bgcolor: grey[500]}} />Confirmar</Button>
            <Button
                fullWidth
                variant='contained'
                color="error"
                onClick={() => 
                    onClose()
                }>Voltar venda</Button>


        </div>
    );
};