import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { productImageQRCodeRepository } from "../product-image-qrcode.repository";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import application from "../../core/application";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { productRepository } from "../product.repository";
import { ProductImageQRCode } from "../product-image-qrcode.entity";
import { Product } from "../product.entity";

export const ProductQrcodePage = () => {
    const { qrcodeId, token } = useParams<{ qrcodeId: string, token: string }>();
    const [productImageQRCode, setProductImageQRCode] = useState<ProductImageQRCode>();
    const [product, setProduct] = useState<Product>();
    const [imageUploading, setImageUploading] = useState(false);
    const [imageUploadingPercent, setImageUploadingPercent] = useState(0);
    const [fineshed, setFineshed] = useState(false);
    const [expired, setExpired] = useState(false);


    useEffect(() => {
        (async() => {

            if (!qrcodeId || !token) {
                setExpired(true);
                window.close();
                return;
            }
    
            const qrCodeImage = await productImageQRCodeRepository.findByIdOrNull(qrcodeId);


            if (!qrCodeImage) {
                setExpired(true);
                return;
            }
    
            const productResult = await productRepository.findByIdOrNull(qrCodeImage.productId);
    
            if (!productResult) {
                setExpired(true);
                return;
            }

            setProductImageQRCode(qrCodeImage);
            setProduct(productResult);
        })();
    }, [qrcodeId, token]);
        

    const onImageChange = async (event: any) => {
        if (!productImageQRCode) return;
        if (!product) return;

        const fileInput = event.target;
        const file = (fileInput as any)?.files[0];

        if (!file) {
            await Swal.fire({
                title: 'Nenhuma imagem selecionada.',
                text: 'Você precisa selecionar uma imagem para continuar.',
                icon: 'error'
            });

            return;
        }

        if (!file.type.startsWith("image/")) {
            Swal.fire({
                title: 'Arquivo inválido.',
                text: 'Você precisa selecionar uma imagem para continuar.',
                icon: 'error'
            });
            return;
        }


        setImageUploading(true);

        const ref = application.getStorageRef(`/products/${product.id}.${file.type.split("/")[1]}`);
        const uploadTask = uploadBytesResumable(ref, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setImageUploadingPercent(progress);
            },
            (error) => {
                console.error('Erro para upar imagem', error);
                Swal.fire({
                    title: 'Erro ao enviar imagem.',
                    text: 'Ocorreu um erro ao enviar a imagem, tente novamente.',
                    icon: 'error'
                })
                setImageUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
                    if (!product) return;

                    product.setImage(downloadURL);
                    await productRepository.save(product);

                    productImageQRCode.usaged = true;
                    await productImageQRCodeRepository.save(productImageQRCode);

                    Swal.fire({
                        title: 'Imagem enviada com sucesso.',
                        text: 'A imagem foi enviada com sucesso.',
                        icon: 'success'
                    });
                    window.close();
                    return;
                });
            }
        );
    };

    if (!product || !productImageQRCode) { 
        return (
            <Backdrop
                sx={{ color: '#fff'}}
                open={true}
            >
                <CircularProgress  color="inherit"  />
            </Backdrop>
        );
    }

    if (imageUploading) {
        return (
            <Backdrop
                sx={{ color: '#fff'}}
                open={true}
            >
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate"  color="inherit"  />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                        >{`${Math.round(imageUploadingPercent)}%`}</Typography>
                    </Box>
                </Box>
            </Backdrop>
        );
    }

    if (expired) {
        return (
            <Box sx={{textAlign: 'center', p: 2}}>
                <Typography variant="h5" component="div">
                    QRCode expirado
                </Typography>
                <Typography variant="body1" component="div">
                    O qrcode foi expirado
                </Typography>
            </Box>
        )
    }

    if (fineshed) {
        return (
            <Box sx={{textAlign: 'center', p: 2}}>
                <Typography variant="h5" component="div">
                    Imagem enviada com sucesso.
                </Typography>
                <Typography variant="body1" component="div">
                    A imagem foi enviada com sucesso.
                </Typography>
            </Box>
        )
    }
    
    return (
        <Box sx={{p: 5}}>
            <input type="file" id="image" title="Imagem para o Produto" style={{display: 'none'}} onChange={onImageChange}/>
            <Button fullWidth color='success' variant="outlined" size="medium" onClick={() => {
                document.getElementById('image')?.click();
            }}>Escolher imagem</Button>
        </Box>
    );
};