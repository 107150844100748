import { FirebaseError } from "firebase/app";
import Swal from "sweetalert2";
import { AppError, AppErrorKey } from "./app-error";
import application from "../core/application";

export const handleError = (error: unknown) => {
    if (error instanceof FirebaseError && error.code === "permission-denied") {
        window.location.href = '/403';
        return;
    }

    console.log('is app', error instanceof AppError);
    console.log(error);

    if (error instanceof AppError) {
        if (error.key === AppErrorKey.BUSSINESS_NOT_DEFINED) {
            Swal.fire({
                title: 'Ocorreu um erro que pode se recuperar!',
                text: 'Ocorreu um erro no sistema no qual pode ser recuperado, clique no botão abaixo para tentar recuperar e efetue a ação novamente.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Recuperar',
                preConfirm: async () => {
                    await application.loadBussiness();
                },
                allowOutsideClick: false,
                showLoaderOnConfirm: true
            })
        }

        if (error.key === AppErrorKey.USER_NOT_LOGGED) {
            window.location.href = '/login';
            return;
        }

        if (error.key === AppErrorKey.PRINTER_NOT_INITIALIZED) {
            Swal.fire({
                title: 'Não foi possível inicializar a impressora',
                text: 'Não foi possível inicializar a impressora, clique no botão abaixo para tentar novamente.',
                icon: 'error',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Tentar novamente',
                preConfirm: async () => {
                    const printer = application.printer.get();

                    if (printer) {
                        await printer.configure();
                        return;
                    }

                    await application.initializePrint();
                    return;
                },
                allowOutsideClick: false,
                showLoaderOnConfirm: true
            })
        }
    }

    Swal.fire({
        title: 'Ocorreu um erro desconhecido',
        text: 'Ocorreu algum erro desconhecido no sistema, tente novamente mais tarde.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Atualizazar página',
        preConfirm: async () => {
            window.location.reload();
        }
    });
};


export const handleErrors = () => {
    window.onerror = (error) => {
        handleError(error);
    };


    window.addEventListener("unhandledrejection", (rejectEvent) => { 
        handleError(rejectEvent.reason);
    });
};