import { ChangeEvent, FormEvent, FormEventHandler, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Product, noImage } from "../product.entity";
import { v4, v5 } from "uuid";
import { productRepository } from "../product.repository";
import { AppError, AppErrorKey } from "../../common/app-error";
import { RepositoryErrorKey } from "../../common/repository.firebase";
import Swal from "sweetalert2";
import { Shell } from "../../common/shell/shell.page";
import { Autocomplete, Backdrop, Box, Button, CircularProgress, TextField, Typography, createFilterOptions } from "@mui/material";
import { Error } from "@mui/icons-material";
import ValueObjectObservable from "../../common/vo/value-object-observable";
import { MoneyInput } from "../../common/components/money-input";
import application from "../../core/application";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { ProductCategory } from "../product-category.entity";
import { productCategoriesRepository } from "../product-categories.repository";
import { ProductSupplier } from "../product-supplier.entity";
import { productSupplierRepository } from "../product-supplier.repository";
import { Permission } from "../../users/user.entity";
import { ProductImageQRCode } from "../product-image-qrcode.entity";
import { SwalReact } from "../../common/swal-react";
import QRCode from "react-qr-code";
import { ProductImageQRCodeRepository, productImageQRCodeRepository } from "../product-image-qrcode.repository";


enum ProductFormMode {
    LOADING,
    ADDING,
    EDITING
}

type ItemAutoComplete = {
    title?: string,
    id: string,
    inputValue?: string
}

class ProductEditableStatefy {
    public readonly attributes = new ValueObjectObservable<{[key: string]: any}>(this.product.toObject());
    public touched = false;

    constructor(public readonly product: Product) {}

    set(attribute: string, value: any) {
        this.touched = true;
        const setter = `set${attribute.charAt(0).toUpperCase() + attribute.slice(1)}`;

        if (typeof (this.product as any)[setter] === "function") {
            (this.product as any)[setter](value);
            this.attributes.set(this.product.toObject());
            return;
        }

        throw new AppError(AppErrorKey.UNKNOWN_ERROR, `Setter ${setter} não encontrado no produto.`);
    }

    async refreshAttribute(attribute: string) {
        const productInFirebase = await productRepository.findById(this.product.id);
        const getter = `get${attribute.charAt(0).toUpperCase() + attribute.slice(1)}`;

        if (typeof (productInFirebase as any)[getter] === "function") {
            const value = (productInFirebase as any)[getter]();
            this.set(attribute, value);
            return;
        }

        throw new AppError(AppErrorKey.UNKNOWN_ERROR, `Getter ${getter} não encontrado no produto.`);
    }

    async save() {
        if (!this.product) return;
        
        if (!this.validate()) return;
        await productRepository.save(this.product);
        
        await Swal.fire({
            title: 'Produto salvo com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
        });
    }

    validate() {
        if (!this.product) return false;

        if (isNaN(this.product.getStock()) || this.product.getStock() < 0) {
            Swal.fire({
                title: 'Estoque inválido.',
                text: 'O estoque precisa ser um número maior ou igual a zero.',
                icon: 'error'
            });
            return false;
        }

        return true;
    }
}

export const ProductFormPage = () => {
    const {productId} = useParams();

    const [mode, setMode] = useState(ProductFormMode.LOADING);
    const [productEditable, setProductEditable] = useState<ProductEditableStatefy>();
    const [productAttributes, setProductAttributes] = useState<{[key: string]: any}>({});
    const [imageUploading, setImageUploading] = useState<boolean>(false);
    const [categories, setCategories] = useState<ProductCategory[]>();
    const [suppliers, setSupplier] = useState<ProductSupplier[]>();
    const [savingProduct, setSalvingProduct] = useState(false);

    const navigate = useNavigate();

    application.useHasPermission(Permission.WRITE_PRODUCTS);

    useEffect(() => {
        productCategoriesRepository.getAll().then(setCategories);
        productSupplierRepository.getAll().then(setSupplier);
    }, []);

    useEffect(() => {
        if (!productEditable) return;
        const eventId = productEditable.attributes.addEventListener((attributes) => {
            setProductAttributes(attributes);
        });
        setProductAttributes(productEditable.attributes.get());

        return () => productEditable.attributes.removeEventListener(eventId);
    }, [productEditable]);


    useEffect(() => {
        if (!productId) {
            setMode(ProductFormMode.ADDING);
            return;
        }

        productRepository.findById(productId).then(product => {
            setProductEditable(new ProductEditableStatefy(product));
            setMode(ProductFormMode.EDITING);
        }).catch((err) => {
            if (err.key === RepositoryErrorKey.ENTITY_NOT_FOUND) {
                setMode(ProductFormMode.EDITING);
                return;
            }

            throw new AppError(AppErrorKey.UNKNOWN_ERROR, err.message, err.options)
        });
    }, [productId]);

    const changeProductImage = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = (fileInput as any)?.files[0];

        if (!file) {
            Swal.fire({
                title: 'Nenhuma imagem selecionada.',
                text: 'Você precisa selecionar uma imagem para continuar.',
                icon: 'error'
            });
            return;
        }

        if (!file.type.startsWith("image/")) {
            Swal.fire({
                title: 'Arquivo inválido.',
                text: 'Você precisa selecionar uma imagem para continuar.',
                icon: 'error'
            });
            return;
        }

        setImageUploading(true);
        const ref = application.getStorageRef(`/products/${productAttributes.id}.${file.type.split("/")[1]}`);
        const uploadTask = uploadBytesResumable(ref, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
            },
            (error) => {
                console.error('Erro para upar imagem', error);
                Swal.fire({
                    title: 'Erro ao enviar imagem.',
                    text: 'Ocorreu um erro ao enviar a imagem, tente novamente.',
                    icon: 'error'
                })
                setImageUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    productEditable?.set('image', downloadURL);
                    productEditable?.save();
                    setImageUploading(false);
                });
            }
        );
    }, [productAttributes, productEditable]);

    const productImageQRCodeGenerate = useCallback(async () => {
        if (!productEditable) return;

        const productQRCode = new ProductImageQRCode(v4(), productEditable.product.id, v4(), false);
        await productImageQRCodeRepository.save(productQRCode);

        const [productQRCodeVO, unsubscribe] = await productImageQRCodeRepository.findByIdObservable(productQRCode.id);

        const QRCodeModal = SwalReact.mixin({
            title: 'QR Code',
            html: (
                <>
                    <p>Escanei o QRCode com o celular para utilizar o celular para enviar a imagem do produto.</p>
                    <QRCode value={`${window.location.protocol}//${window.location.host}/produtos/qrcode/${productQRCode.id}/${productQRCode.token}}`} />
                </>
            ),
            didClose: () => {
                productImageQRCodeRepository.remove(productQRCode);
                unsubscribe();
            },
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Fechar'
        });

        productQRCodeVO.addEventListener((qrCode) => {
            if (!qrCode) return;
            if (!qrCode.usaged) return;

            productEditable.refreshAttribute('image');
            QRCodeModal.close();
            Swal.fire({
                title: 'Imagem alterada com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        });

        QRCodeModal.fire();

    }, [productEditable]);
        
    const addNewProductSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const code = (event.target as any).code.value;

        if (!code) {
            Swal.fire({
                title: 'Código não informado.',
                text: 'Você precisa informar o código do produto para continuar.',
                icon: 'error'
            });
            return;
        }

        if (await productRepository.exists(code)) {
            const result = await Swal.fire({
                title: 'Código já cadastrado!',
                text: `O código ${code} informado já está cadastrado para outro produto.`,
                icon: 'error',
                confirmButtonText: 'Informar outro código',
                showDenyButton: true,
                denyButtonText: 'Editar produto existente com este código'
            });

            if (result.isDenied) {
                navigate(`/produtos/editar/${code}`);
            }

            return;
        }

        const newProduct = new Product(code, "", 0.0, noImage);
        await productRepository.save(newProduct);
        setProductEditable(new ProductEditableStatefy(newProduct));
    }, [navigate]);

    if (mode === ProductFormMode.LOADING) {
        return (
            <Backdrop
            sx={{ color: '#fff', zIndex: 999 }}
            open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    if (mode === ProductFormMode.ADDING && !productEditable) {
        return (
            <Shell title="Produto">
                <div>
                    <Typography component="p" variant="h5" color="text.primary">Adicionar novo produto</Typography>
                    <hr />
                    <Typography component="p" variant="body1" color="text.secondary" >
                        Para iniciar a criação de um novo produto, digite o
                        código do produto.
                    </Typography>

                    <Box sx={{mt: 2, mb: 1}}>
                        <form onSubmit={addNewProductSubmit}>
                            <TextField
                                id="code"
                                label="Código do produto"
                                variant="outlined"
                                fullWidth
                                autoFocus={true}
                            />

                            <Button sx={{mt: 2}} fullWidth variant="contained" color="success" type="submit">Continuar</Button>
                        </form>
                    </Box>
                    <Button color="error" onClick={() => navigate('/produtos')} fullWidth variant="contained">Cancelar</Button>
                </div>
            </Shell>
        );
    }

    if (!productEditable) {
        return (
            <Shell title="Produto">
                <Box sx={{textAlign: 'center'}}>
                    <Error color="error" sx={{fontSize: 60}} />
                    <Typography sx={{fontSize: 15, mb: 1}} variant="body1">Produto não foi encontrado.</Typography>
                    <Button color="info" onClick={() => navigate('/produtos')} fullWidth variant="contained">Voltar</Button>
                </Box>
            </Shell>
        );
    }

    if (!productAttributes) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: 99999999}}
                open={imageUploading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <Shell title="Produto">
            <Box sx={{textAlign: 'center'}}>
                <input type="file" id="fileImage" name="fileImage" style={{display: 'none'}} title="Adicionar foto" onChange={changeProductImage} />
                <form>
                    <Box>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 99999999}}
                            open={imageUploading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <img src={productAttributes.image} alt="Imagem do produto" style={{height: '250px'}} />
                        <Button fullWidth color='info' variant="contained" onClick={() => {document.getElementById('fileImage')?.click()}}>Alterar imagem</Button>
                        {((('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || ((navigator as any).msMaxTouchPoints > 0))) ? null : (
                            <Button sx={{mt: 1}} fullWidth color='warning' variant="contained" onClick={() => productImageQRCodeGenerate()}>Alterar imagem com celular</Button>
                        )}
                        <Button fullWidth color='error' variant="contained" sx={{mt: 1}} disabled={noImage === productAttributes.image} onClick={() => {productEditable.set('image', noImage); productEditable.save()}}>Remover imagem</Button>
                    </Box>

                    <TextField
                        id="code"
                        label="Código do produto"
                        variant="outlined"
                        fullWidth
                        value={productAttributes.id || ''}
                        disabled={true}
                        sx={{mt: 5}}
                    />

                    <TextField
                        id="name"
                        sx={{mt: 2}}
                        label={productAttributes.name ? "Nome do produto" : null}
                        placeholder="Produto sem título"
                        variant="outlined"
                        fullWidth
                        value={productAttributes.name || ''}
                        onChange={(event) => productEditable.set('name', event.target.value)}
                    />

                    <MoneyInput
                        value={productAttributes.price}
                        onChange={changed => productEditable.set('price', changed.value)}
                        title={"Valor do Produto"}
                        textFieldProps={{
                            sx: {mt: 2},
                            fullWidth: true
                        }}
                    />

                    <TextField
                        id="stock"
                        sx={{mt: 2}}
                        label="Estoque"
                        type="number"
                        placeholder="Sem estoque"
                        variant="outlined"
                        fullWidth
                        value={productAttributes.stock || ''}
                        onChange={(event) => {
                            productEditable.set('stock', event.target.value)
                        }}
                    />

                    <Autocomplete
                        value={{
                            title: categories?.find((category) => category.id === productAttributes.category)?.title,
                            id: productAttributes.category
                        }}
                        onChange={(event, newValue) => {
                            (event.target as any).blur();
                            if (typeof newValue === 'string') {
                                const existingCategory = categories?.find(category => category.title === newValue)

                                if (existingCategory) {
                                    productEditable.set('category', existingCategory);
                                    return;
                                }
                            }

                            if (newValue && typeof newValue === 'object' && newValue.id !== "_new") {
                                productEditable.set('category', newValue);
                                return;
                            }


                            const newTitle = typeof newValue === "string" ? newValue : newValue?.inputValue;
                            if (!newTitle) {
                                return;
                            }

                            const newCategory = new ProductCategory(v4(), newTitle, '');
                            productCategoriesRepository.save(newCategory);
                            productEditable.set('category', newCategory);
                            setCategories((items) => items ? [...items, newCategory] : [newCategory]);
                            return;
                        }}
                        filterOptions={(options, params) => {
                            const filtered = createFilterOptions<ItemAutoComplete>()(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    id: '_new',
                                    title: `Adicionar "${inputValue}"`,
                                    inputValue
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={categories?.map((category) => ({
                            title: category.title,
                            id: category.id
                        } as ItemAutoComplete)) || []}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }

                            return option.title || '';
                        }}
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                        freeSolo
                        loading={categories === undefined}
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params} label="Categoria do Produto" sx={{mt: 2}} fullWidth />
                        )}
                    />

                    <Autocomplete
                        value={{
                            title: suppliers?.find((supplier) => supplier.id === productAttributes.supplier)?.title,
                            id: productAttributes.supplier
                        }}
                        onChange={(event, newValue) => {
                            (event.target as any).blur();
                            if (typeof newValue === 'string') {
                                const existingSupplier = suppliers?.find(supplier => supplier.title === newValue)

                                if (existingSupplier) {
                                    productEditable.set('supplier', existingSupplier);
                                    return;
                                }
                            }

                            if (newValue && typeof newValue === 'object' && newValue.id !== "_new") {
                                productEditable.set('supplier', newValue);
                                return;
                            }


                            const newTitle = typeof newValue === "string" ? newValue : newValue?.inputValue;
                            if (!newTitle) {
                                return;
                            }

                            const newSupplier = new ProductCategory(v4(), newTitle, '');
                            productSupplierRepository.save(newSupplier);
                            productEditable.set('supplier', newSupplier);
                            setSupplier((items) => items ? [...items, newSupplier] : [newSupplier]);
                            return;
                        }}
                        filterOptions={(options, params) => {
                            const filtered = createFilterOptions<ItemAutoComplete>()(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    id: '_new',
                                    title: `Adicionar "${inputValue}"`,
                                    inputValue
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={suppliers?.map((supplier) => ({
                            title: supplier.title,
                            id: supplier.id
                        } as ItemAutoComplete)) || []}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }

                            return option.title || '';
                        }}
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                        freeSolo
                        loading={suppliers === undefined}
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params} label="Fornecedor" sx={{mt: 2}} fullWidth />
                        )}
                    />

                    <Button sx={{mt: 2}} fullWidth variant="contained" color="success" disabled={savingProduct} onClick={async() => {
                        setSalvingProduct(true);
                        await productEditable.save();
                        setSalvingProduct(false);
                    }}>
                        {savingProduct ? <CircularProgress size={24} /> : 'Salvar'}
                    </Button>
                </form>
                <Button sx={{mt: 2}} fullWidth variant="contained" color={productEditable.touched ? 'error' : 'info'} onClick={() => {
                    if (productEditable.touched) {
                        Swal.fire({
                            title: 'Tem certeza?',
                            text: "Você perderá todas as alterações feitas neste produto!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Sim, tenho certeza!',
                            cancelButtonText: 'Continuar editando'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/produtos');
                            }
                        });

                        return;
                    }

                    navigate('/produtos');
                }}>
                    {productEditable.touched ? 'Cancelar' : 'Voltar'}
                </Button>
            </Box>
        </Shell>
    );
}