import { Button, Chip, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import { Sell } from "../sell.entity";
import { SellProduct } from "../sell-product";
import { v4 } from "uuid";
import { Product, noImage } from "../../product/product.entity";
import { sellRepository } from "../sell.repository";
import { productRepository } from "../../product/product.repository";
import { SellUnregisterProduct } from "../sell-unregister-product";

type XeroxComponentProps = {
    onClose: () => void,
    sell: Sell
}

export const XEROX_PRODUCT_ID = 'xerox';

enum Xerox {
    XEROX = 'xerox',
    IMPRESSAO = 'xerox-impressao',
    BOLETO = 'xerox-boleto',
    ENCADERNACAO = 'xerox-encardenacao',
    OTHER = 'xerox-other'
}

const methods = [
    {
        title: 'Xerox',
        value: Xerox.XEROX
    },
    {
        title: 'Impressão',
        value: Xerox.IMPRESSAO
    },
    {
        title: 'Boleto',
        value: Xerox.BOLETO
    },
    {
        title: 'Encadernação',
        value: Xerox.ENCADERNACAO
    }
]

export const XeroxComponent = ({onClose, sell}: XeroxComponentProps) => {
    const [selected, setSelected] = useState(0);
    const [money, setMoney] = useState<number>(0);
    const [moneyDisplay, setMoneyDisplay] = useState<string>('');
    const [moneyInput, setMoneyInput] = useState(false);

    const confirmMoney = useCallback(async() => {

        let product = new SellUnregisterProduct(v4(), methods[selected].title, 0, noImage);

        sell.addProduct(new SellProduct(product, 1, money))
        sellRepository.save(sell);
        onClose();
    }, [money, sell, selected]);

    const confirmMethod = useCallback(() => {
        setMoneyInput(true);
    }, []);

    useEffect(() => {
        const keyEvent = (event: KeyboardEvent) => {
            if (event.key === 'ArrowUp') {
                setSelected(selected => {
                    if (selected === 0) {
                        return methods.length-1
                    }

                    return selected-1;
                });
                return;
            }

            if (event.key === 'ArrowDown') {
                setSelected(selected => {
                    if (selected === methods.length-1) {
                        return 0;
                    }
    
                    return selected+1;
                });
                return;
            }

            if (event.key === 'Enter') {
                if (moneyInput) {
                    confirmMoney();
                    return;
                }

                confirmMethod();
                return;
            }

            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener("keyup", keyEvent);

        return () => document.removeEventListener("keyup", keyEvent);
    }, [confirmMethod, confirmMoney, moneyInput, onClose]);

    if (moneyInput) {
        return (
            <div className="finish-sell money">
                <h1 className="title">Valor total do {methods[selected].title}?</h1>

                <FormControl fullWidth>
                    <InputLabel htmlFor="amount">Valor total</InputLabel>
                    <OutlinedInput
                        id="money"
                        label="Amount"
                        value={moneyDisplay}
                        onBlur={(event) => event.target.focus()}
                        autoFocus={true}
                        onChange={(event) => {
                            let value = event.target.value.replace(/[^0-9]/g, '');
                            setMoney(parseFloat(value) / 100);
                            value = new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            }).format(Number(value) / 100).replaceAll("[^0123456789.,]","");
                            setMoneyDisplay(value);
                        }}  
                    />
                </FormControl>

                <Button
                    fullWidth
                    variant='contained'
                    color="success"
                    onClick={() => 
                        confirmMoney()
                    }><Chip label='Enter' sx={{bgcolor: grey[500]}} />Confirmar</Button>
                <Button
                fullWidth
                variant='contained'
                color="error"
                onClick={() => 
                    onClose()
                }>Voltar venda</Button>
            </div>
        );
    }

    return (
        <div className="finish-sell">
            <h1 className="title">Impressão</h1>

            <div className="methods">
                {methods.map((method, index) => (
                    <p
                        className={selected === index ? 'selected' : ''}
                        key={index}
                        onClick={() => {setSelected(index);}}
                    >{method.title}</p>
                ))}
            </div>

            <Button
                fullWidth
                variant='contained'
                color="success"
                onClick={() => {
                    confirmMethod()
                }}><Chip label='Enter' sx={{bgcolor: grey[500]}} />Confirmar</Button>
            <Button
                fullWidth
                variant='contained'
                color="error"
                onClick={() => 
                    onClose()
                }>Voltar venda</Button>
        </div>
    )
};
