import serializable from "../common/decorators/serializable";
import { EntityFirebase } from "../common/entity.firebase";

@serializable
export class ProductSupplier extends EntityFirebase {
    constructor(
        public readonly id: string,
        public title: string
    ) {
        super();
    }

    static async build(id: string, data: any) {
        return new ProductSupplier(id, data.title);
    }
}