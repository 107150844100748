import { DocumentData, DocumentReference, Query, deleteDoc, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import application from "../core/application";
import { EntityFirebase } from "./entity.firebase";
import { AppError } from "./app-error";
import { useEffect, useState } from "react";
import { Product } from "../product/product.entity";
import { PagenedResult } from "./pagened-result";
import ValueObjectObservable from "./vo/value-object-observable";
import { Entity } from "./entity";

export enum RepositoryErrorKey {
    ENTITY_NOT_FOUND = 'entity_not_found'
}

export abstract class RepositoryFirebase<T extends EntityFirebase> {
    protected abstract collection: string;
    protected abstract entity: new (...args: any[]) => T;

    async findById(id: string): Promise<T> {
        const ref = this.getCollection();

        const docSnap = await getDoc(doc(ref, id));

        if (!docSnap.exists()) {
            throw new AppError(RepositoryErrorKey.ENTITY_NOT_FOUND);
        }

        return await (this.entity as any).build(docSnap.id, docSnap.data());
    }

    async findByIdOrNull(id: string): Promise<T|null> {
        try {
            return await this.findById(id);
        } catch (error) {
            if (error instanceof AppError && error.key === RepositoryErrorKey.ENTITY_NOT_FOUND) return null;

            throw error;
        }
    }

    async save(entity: EntityFirebase) {
        const ref = this.getCollection();
        const {id, ...data} = entity.toObject();
        await setDoc(doc(ref, id), data)
        return true;
    }

    async remove(entity: EntityFirebase) {
        const ref = this.getCollection();
        await deleteDoc(doc(ref, entity.id));
        return true;
    }

    async exists(id: string) {
        const ref = this.getCollection();
        const docSnap = await getDoc(doc(ref, id));
        return docSnap.exists();
    }

    public async getAll(orderByTo?: string) {
        const ref = this.getCollection();

        let queryRef = query(ref);

        if (orderByTo) {
            queryRef = query(queryRef, orderBy(orderByTo));
        }

        const docsSnap = await getDocs(queryRef);

        return await Promise.all(docsSnap.docs.map(async(item) => {
            return await (this.entity as any).build(item.id, item.data());
        }));
    }

    public async getAllPagened(limitTo: number, orderByTo: string = 'name') {
        const ref = this.getCollection();
        const querySnap = query(ref, orderBy(orderByTo), limit(limitTo));
        const snapshot = await getDocs(querySnap);

        const items = await Promise.all(snapshot.docs.map(async(item) => {
            const entity = await (this.entity as any).build(item.id, item.data());
            return entity;
        }));

        return new PagenedResult<T>(
            items,
            snapshot.docs[0],
            snapshot.docs[snapshot.docs.length - 1],
            querySnap,
            limitTo,
            this.entity
        );
    }

    protected get useSubscribeQuery() {
        return (query: Query<DocumentData>) => {
            const [values, setValues] = useState<Array<T>>([]);

            useEffect(() => {
                const unsubscribe = onSnapshot(query, async(snapshot) => {
                    const items = await Promise.all(snapshot.docs.map(async(item) => {
                        return await (this.entity as any).build(item.id, item.data());
                    }));

                    setValues(items);
                });

                return () => unsubscribe();
            }, []);

            return [values];
        }
    }

    get useFindById() {
        return (id: string, onFailure?: (() => void)) => {
            const [value, setValues] = useState<T>();

            useEffect(() => {
                const unsubscribe = onSnapshot(doc(this.getCollection(), id), async(snapshot) => {
                    if (!snapshot.exists()) {
                        if (onFailure) {
                            return onFailure();
                        }

                        throw new AppError(RepositoryErrorKey.ENTITY_NOT_FOUND)
                    };

                    setValues(await (this.entity as any).build(snapshot.id, snapshot.data()));
                });

                return () => unsubscribe();
            }, []);

            return [value];
        }
    }

    findByIdObservable(id: string): [ValueObjectObservable<T|undefined>, () => void] {
        const ref = this.getCollection();
        const entity = new ValueObjectObservable<T|undefined>(undefined);

        const unsubscribe = onSnapshot(doc(ref, id), async(snapshot) => {
            if (!snapshot.exists()) {
                throw new AppError(RepositoryErrorKey.ENTITY_NOT_FOUND);
            }

            entity.set(await (this.entity as any).build(snapshot.id, snapshot.data()));
        });

        return [entity, unsubscribe];
    }

    protected getCollection() {
        return application.getCollection(this.collection);
    }
}