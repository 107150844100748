import { useEffect, useState } from "react";
import ValueObjectObservable from "./value-object-observable";

export default class ValueObjectStateify<T> extends ValueObjectObservable<T> {

    get useState() {
        return () => {
            const [value, setValue] = useState(this.value);

            useEffect(() => {
                const listener = this.valueChange.addListener((event) => {
                    setValue(event);
                });

                return () => {
                    this.valueChange.removeListener(listener);
                };
            }, []);

            return [value];
        };
    }
}