import { Alert, Autocomplete, Button, FormControl, InputLabel, OutlinedInput, TextField, createFilterOptions } from "@mui/material";
import { useEffect, useState } from "react";
import { productCategoriesRepository } from "../../product/product-categories.repository";
import { ProductCategory } from "../../product/product-category.entity";
import { v4 } from "uuid";

type ItemAutoComplete = {
    title?: string,
    id: string,
    inputValue?: string,
}

type AddProductSellProps = {
    addOnlyPrice: () => void
}

export const AddProductSell = ({addOnlyPrice}: AddProductSellProps) => {
    const [amount, setAmount] = useState('');
    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const [categorySelected, setCategorySelected] = useState<string>();

    useEffect(() => {
        (async() => {
            const categories = await productCategoriesRepository.getAll();
            setCategories(categories);
        })();
    }, []);

    /*useEffect(() => {
        const keyEvent = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (document.activeElement?.id === 'name') {
                    document.getElementById('amount')?.focus();
                    event.stopPropagation();
                    event.preventDefault();
                    return;
                }

                if (document.activeElement?.id === 'amount') {
                    (document.querySelector('.swal2-confirm') as any).click();
                    event.stopPropagation();
                    event.preventDefault();
                    return;
                }
            }
        };

        document.addEventListener('keypress', keyEvent);

        return () => document.removeEventListener('keypress', keyEvent);
    }, []); */

    return (
        <form>
            <Button fullWidth color='info' variant="contained" onClick={() => addOnlyPrice()}>Adicionar somente preço sem cadastro</Button>
            <br />


            <Alert severity="error" id="amountError" style={{opacity: 0}}>Precisa preencher as informações do produto.</Alert>


            <br />
            <FormControl fullWidth>
                <TextField
                    id="name"
                    label="Nome"
                    variant="outlined"
                    className="sell"
                    placeholder="Produto"
                    autoFocus={true}
                    fullWidth
                />
            </FormControl>

            <br /><br />

            <FormControl fullWidth>
                <InputLabel htmlFor="amount">Preço</InputLabel>
                <OutlinedInput
                    id="amount"
                    label="Amount"
                    value={amount}
                    onChange={(event) => {
                        let value = event.target.value.replace(/[^0-9]/g, '');
                        event.target.dataset.amount = String(parseFloat(value) / 100);
                        value = new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(Number(value) / 100).replaceAll("[^0123456789.,]","");
                        setAmount(value);
                    }}  
                />
            </FormControl>

            <br /><br />
            <FormControl fullWidth>
                <TextField
                    id="stock"
                    type="number"
                    label="Estoque (opcional)"
                    variant="outlined"
                    placeholder="Estoque"
                    defaultValue={1}
                    fullWidth
                />
            </FormControl>

            <br /><br />
            <Autocomplete
                value={{
                    title: categories?.find((category) => category.id === categorySelected)?.title,
                    id: categorySelected ?? ''
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        const existingCategory = categories?.find(category => category.title === newValue)

                        if (existingCategory) {
                            setCategorySelected(existingCategory.id);
                            return;
                        }
                    }

                    if (newValue && typeof newValue === 'object' && newValue.id !== "_new") {
                        setCategorySelected(newValue.id);
                        return;
                    }


                    const newTitle = typeof newValue === "string" ? newValue : newValue?.inputValue;
                    if (!newTitle) {
                        return;
                    }

                    const newCategory = new ProductCategory(v4(), newTitle, '');
                    productCategoriesRepository.save(newCategory);
                    setCategorySelected(newCategory.id);
                    setCategories((items) => items ? [...items, newCategory] : [newCategory]);
                    return;
                }}
                filterOptions={(options, params) => {
                    const filtered = createFilterOptions<ItemAutoComplete>()(options, params);

                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            id: '_new',
                            title: `Adicionar "${inputValue}"`,
                            inputValue
                        });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={categories?.map((category) => ({
                    title: category.title,
                    id: category.id
                } as ItemAutoComplete)) || []}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }

                    return option.title || '';
                }}
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                freeSolo
                loading={categories === undefined}
                fullWidth
                openOnFocus={true}
                renderInput={(params) => (
                    <TextField {...params} label="Categoria do Produto" fullWidth />
                )}
            />

            <input type="hidden" id="categoryId" value={categorySelected} />
        </form>
    );
};