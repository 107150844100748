import serializable, { Serializable } from "../common/decorators/serializable";
import { Entity } from "../common/entity";

@serializable
export class Bussiness extends Serializable implements Entity {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly domain: Array<string>,
        public readonly image: string,
        public readonly key: string
    ) {
        super();
    } 
}