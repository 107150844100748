import serializable from "../common/decorators/serializable";
import { EntityFirebase } from "../common/entity.firebase";

export enum Permission {
    /** VIEW */
    SELL = 'SELL',

    /** EDIT */
    WRITE_PRODUCTS = 'WRITE_PRODUCTS',
    READ_WRITE_PRODUCTS_PRIVATE = 'READ_WRITE_PRODUCTS_PRIVATE',

    READ_WRITE_USERS = 'READ_WRITE_USERS',
};

export const permissionsLabel = {
    [Permission.SELL]: 'Vendas e ver produtos',
    [Permission.WRITE_PRODUCTS]: 'Adicionar e editar produtos',

    [Permission.READ_WRITE_PRODUCTS_PRIVATE]: 'Ver e editar dados privados de produtos (preco de compra...)',
    [Permission.READ_WRITE_USERS]: 'Ver e criar usuários'
}


@serializable
export class User extends EntityFirebase {
    constructor(
        public readonly id: string,
        public username: string,
        public name: string,
        public permissions: Permission[],
    ) {
        super();
    }

    static async build(id: string, data: any) {
        return new this(
            id,
            data.username,
            data.name,
            data.permissions,
        );;
    }

    hasPermission(permission: Permission|Permission[]) {
        if (Array.isArray(permission)) {
            return permission.some(p => this.permissions.includes(p));
        }

        return this.permissions.includes(permission);
    }
}