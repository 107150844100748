
import { onAuthStateChanged } from "firebase/auth";
import { Bussiness } from "../bussiness/bussiness.entity";
import bussinessQuery, { BussinessQueryErrorKey } from "../bussiness/bussiness.query";
import { AppError, AppErrorKey } from "../common/app-error";
import ValueObjectStateify from "../common/vo/value-object-stateify";
import firebase from "./firebase";
import ValueObjectObservable from "../common/vo/value-object-observable";
import { collection } from "firebase/firestore";
import Swal from "sweetalert2";
import { Print } from "./print";
import { ref } from "firebase/storage";
import { Permission, User } from "../users/user.entity";
import { userRepository } from "../users/user.repository";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


class Application {
    static instance = new Application();
    public readonly user = new ValueObjectStateify<User|null>(null);
    public readonly authReady = new ValueObjectObservable<boolean>(false);
    public readonly printer = new ValueObjectObservable<Print|undefined>(undefined);
    public readonly bussiness = new ValueObjectStateify<Bussiness|undefined>(undefined);

    private userUnsubscribe: (() => void)|undefined;



    private constructor() {
        onAuthStateChanged(firebase.auth, async(user) => {
            if (!user) {
                if (this.userUnsubscribe) this.userUnsubscribe();
                this.authReady.set(true);
                return
            }

            await this.loadBussiness();
            this.user.set(await userRepository.findById(user.uid));

            const [userObservable, userUnsubscribe] = userRepository.findByIdObservable(user.uid);
            userObservable.addEventListener((user) => {
                if (!user) return;
                if (user === this.user.get()) return;
                this.user.set(user);
            });

            this.userUnsubscribe = userUnsubscribe;

            this.authReady.set(true);
        });
    }
 
    async loadBussiness() {
        if (this.bussiness.get()) return;
        const bussiness = await bussinessQuery.queryByDomain(window.location.hostname);
        if (!bussiness) throw new AppError(BussinessQueryErrorKey.DOMAIN_NOT_FOUND);

        this.bussiness.set(bussiness);
    };

    async initializePrint() {
        const print = new Print();
        
        try {
            await print.configure();
        } catch(err) {
            return;
        }

        this.printer.set(print);
    }

    getCollection(collectionName: string) {
        const bussiness = this.bussiness.get();

        if (!bussiness) {
            throw new AppError(AppErrorKey.BUSSINESS_NOT_DEFINED);
        }
        
        return collection(firebase.db, `${bussiness.key}/items/${collectionName}`)
    }

    getStorageRef(path: string) {
        const bussiness = this.bussiness.get();

        if (!bussiness) {
            throw new AppError(AppErrorKey.BUSSINESS_NOT_DEFINED);
        }

        return ref(firebase.storage, `${bussiness.key}/${path}`);
    }

    getPrint(): Print {
        const printer = this.printer.get();
        if (!printer) throw new AppError(AppErrorKey.PRINTER_NOT_INITIALIZED);

        return printer;
    }

    get useHasPermission() {
        return (permission: Permission|Permission[]) => {
            const navigate = useNavigate();

            const [user] = this.user.useState();

            useEffect(() => {
                if (!user) return navigate("/login");

                if (!user.hasPermission(permission)) {
                    navigate('/403');
                }
            }, [user]);
        };
    }   
}

export default Application.instance;