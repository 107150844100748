import { useCallback, useEffect, useState } from "react";
import { Shell } from "../../common/shell/shell.page";
import { PagenedResult } from "../../common/pagened-result";
import { Product } from "../product.entity";
import { productRepository } from "../product.repository";
import { ListItemAvatar, Avatar, ListItemText, ListItemButton, IconButton, Typography, Divider, Button, ToggleButton, ToggleButtonGroup, Backdrop, CircularProgress } from "@mui/material";
import { List, ListItem } from "@mui/material";

import './products.page.scss';
import { Delete, Edit, Filter } from "@mui/icons-material";
import React from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SwalReact } from "../../common/swal-react";
import { ProductCategory } from "../product-category.entity";
import { ProductSupplier } from "../product-supplier.entity";
import { productCategoriesRepository } from "../product-categories.repository";
import { productSupplierRepository } from "../product-supplier.repository";
import application from "../../core/application";
import { Permission } from "../../users/user.entity";

enum FilterBy {
    NONE = 'none',
    CATEGORY = 'category',
    SUPPLIER = 'supplier'
}

type Filter = {
    type: FilterBy,
    value: string,
    title: string
}

const PRODUCTS_PAGENED_LIMIT = 5;

export const ProductsPage = () => {
    const [productsPagened, setProductsPagened] = useState<PagenedResult<Product>>();
    const [hasNext, setHasNext] = useState<boolean>(true);
    const [hasPrevius, setHasPrevius] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const [suppliers, setSuppliers] = useState<ProductSupplier[]>([]);
    const [filter, setFilter] = useState<Filter>({type: FilterBy.NONE, value: '', title: ''});

    const [user] = application.user.useState();

    application.useHasPermission([Permission.SELL, Permission.WRITE_PRODUCTS]);

    useEffect(() => {
        if (loading) return;
        if (filter.type === FilterBy.CATEGORY) {
            if (!filter.value) return;

            productRepository.getByCategoryIdPagened(filter.value, PRODUCTS_PAGENED_LIMIT).then(products => {
                setProductsPagened(products);
            });
            return;
        }

        if (filter.type === FilterBy.SUPPLIER) {
            if (!filter.value) return;

            productRepository.getBySupplierIdPagened(filter.value, PRODUCTS_PAGENED_LIMIT).then(products => {
                setProductsPagened(products);
            });
            return;
        }

        productRepository.getAllPagened(PRODUCTS_PAGENED_LIMIT).then(products => {
            setProductsPagened(products);
        });
    }, [filter, loading])

    useEffect(() => {
        (async() => {
            const productsPagenedResult = await productRepository.getAllPagened(PRODUCTS_PAGENED_LIMIT, "name");
            setProductsPagened(productsPagenedResult);

            const categories = await productCategoriesRepository.getAll();
            setCategories(categories);

            const suppliers = await productSupplierRepository.getAll();
            setSuppliers(suppliers);


            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async() => {
            if (!productsPagened) return;
            setHasNext(await productsPagened.hasNext());
            setHasPrevius(await productsPagened.hasPrevius());
        })();
    }, [productsPagened]);

    const nextPage = useCallback(async() => {
        if (!productsPagened) return;
        const next = await productsPagened.next();
        setProductsPagened(next);
    }, [productsPagened]);

    const previusPage = useCallback(async() => {
        if (!productsPagened) return;
        const previus = await productsPagened.previus();
        setProductsPagened(previus);
    }, [productsPagened]);

    const removeProduct = useCallback(async(product: Product) => {
        if (!productsPagened) return;
        const result = await Swal.fire({
            title: `Deseja realmente excluir o produto ${product.getName()}?`,
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            icon: 'warning'
        });

        if (!result.isConfirmed) return;
        
        await productRepository.remove(product);
        const productsPagenedResult = await productsPagened.refreshItens();
        setProductsPagened(productsPagenedResult);
    }, [productsPagened]);

    if (loading) return (
        <Backdrop
        sx={{ color: '#fff', zIndex: 999 }}
        open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );

    if (!productsPagened) return (
        <Shell title="Produtos">
            <div>Nenhum produto encontrado</div>
        </Shell>
    );
    
    return (
        <Shell title="Produtos">
            <div className="products-page">
                {user?.hasPermission([Permission.WRITE_PRODUCTS]) && (
                    <>
                        <Button fullWidth color='info' variant="contained" onClick={() => navigate('/produtos/novo')}>Adicionar produto</Button>
                        <Button fullWidth color='secondary' variant="contained" onClick={async() => {
                            SwalReact.fire({
                                title: 'Buscar por',
                                input: 'text',
                                inputPlaceholder: 'Digite o código do produto',
                                showCancelButton: true,
                                confirmButtonText: 'Buscar',
                                cancelButtonText: 'Cancelar',
                                preConfirm: async (value) => {
                                    if (!value) {
                                        SwalReact.showValidationMessage('Digite o código do produto');
                                        return;
                                    }

                                    if (!await productRepository.exists(value)) {
                                        SwalReact.showValidationMessage('Produto não encontrado');
                                        return;
                                    }

                                    navigate(`/produtos/editar/${value}`);
                                }
                            });

                        }} sx={{mt: 1}}>Buscar por Código</Button>
                    </>
                )}

                <ToggleButtonGroup
                    color="primary"
                    value={filter.type}
                    exclusive
                    onChange={async(event, filterType) => { 
                        await SwalReact.fire({
                            title: 'Filtrar por',
                            input: 'select',
                            inputOptions: filterType === FilterBy.CATEGORY ?
                                    categories.reduce((acc, category) => ({...acc, [category.id]: category.title}), {})
                                    : suppliers.reduce((acc, supplier) => ({...acc, [supplier.id]: supplier.title}), {}),
                            inputPlaceholder: 'Selecione uma opção',
                            showCancelButton: true,
                            confirmButtonText: 'Filtrar',
                            cancelButtonText: 'Cancelar',
                            preConfirm: async (value) => {
                                if (!value) {
                                    SwalReact.showValidationMessage('Selecione uma opção');
                                    return;
                                }

                                setFilter({
                                    type: filterType,
                                    value,
                                    title: filterType === FilterBy.CATEGORY ?
                                        categories.find(category => category.id === value)?.title || '' :
                                        suppliers.find(supplier => supplier.id === value)?.title || ''});
                            }
                        });
                    }}
                    aria-label="Platform"
                    fullWidth
                    sx={{mt: 1}}
                >
                    <ToggleButton value={FilterBy.CATEGORY}>{filter.type === FilterBy.CATEGORY ? filter.title : 'Filtar Categoria'}</ToggleButton>
                    <ToggleButton value={FilterBy.SUPPLIER}>{filter.type === FilterBy.SUPPLIER ? filter.title : 'Filtar Fornecedor'}</ToggleButton>
                </ToggleButtonGroup>

                {filter.type !== FilterBy.NONE && (
                    <Button sx={{mt: 1}} variant="outlined" color='error' fullWidth onClick={() => setFilter({
                        type: FilterBy.NONE,
                        value: '',
                        title: ''
                    })}>Remover filtro</Button>
                )}


                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem component="a" href="#customized-list">
                        <ListItemText
                            sx={{ my: 0 }}
                            primary="Produtos"
                            primaryTypographyProps={{
                                fontSize: 20,
                                fontWeight: 'medium',
                                letterSpacing: 0,
                            }}
                        />
                    </ListItem>
                    {productsPagened?.itens.map(product => (
                        <>
                        <Divider />
                        <ListItem key={product.id} secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="Editar" onClick={() => navigate(`/produtos/editar/${product.id}`)}>
                                    <Edit />
                                </IconButton>

                                <IconButton edge="end" aria-label="Remover" onClick={() => removeProduct(product)}>
                                    <Delete />
                                </IconButton>
                            </>
                        }>
                            <ListItemAvatar>
                                <Avatar src={product.getImage()} />
                            </ListItemAvatar>
                            <ListItemText primary={product.getName() || 'Produto sem título'} secondary={
                                <>
                                    <Typography
                                        component="p"
                                        variant="subtitle1"
                                        color="text.primary"
                                    >
                                        {product.getPrice().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </Typography>
                                    <Typography
                                        component="p"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {product.id}
                                    </Typography>
                                    
                                </>
                            } />
                        </ListItem>
                        </>
                    ))}
                </List>

                <Button fullWidth color='info' variant="outlined" disabled={hasPrevius} onClick={() => previusPage()}>{'< Pagina anterior'}</Button>
                <Button fullWidth color='info' variant="outlined" sx={{mt: 2}} disabled={hasNext} onClick={() => nextPage()}>{'Proxima pagina >'}</Button>
            </div>
        </Shell>
    )
};