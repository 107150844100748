import "reflect-metadata";

const propretyNotSerializableSymbol = Symbol("propretyNotSerializableSymbol");
const propretyCustomSerializableSymbol = Symbol("propretyCustomSerializableSymbol");


const serializable = <T extends { new (...args: any[]): {} }>(constructor: T) => {
    return class extends constructor {
        toObject() {
            const object: any = {};
            const propreties = Object.getOwnPropertyDescriptors(this) as any;
            Object.entries(propreties).forEach(([key, proprety]: any) => {
                const isNotSerializable = Reflect.getMetadata(propretyNotSerializableSymbol, this, key);
                const customSerializable = Reflect.getMetadata(propretyCustomSerializableSymbol, this, key);

                if (isNotSerializable) {
                    return;
                }

                if (customSerializable) {
                    object[key] = customSerializable(proprety.value)
                    return;
                }

                if (typeof proprety.value == "object") {
                    if (proprety.value == null) {
                        object[key] = null;
                        return;
                    }

                    if (typeof proprety.value?.toObject == "function") {
                        object[key] = proprety.value.toObject();

                        if(Array.isArray(object[key])) {
                            object[key] = object[key].map((item: any) => {
                                if (typeof item == "object" ) {
                                    if (typeof item.toObject == "function") {
                                        return item.toObject();
                                    }

                                    if (typeof item.get == "function") {
                                        return item.get();
                                    }
                                }

                                return item;
                            });
                        }

                        return;
                    }

                    if (typeof proprety.value?.get == "function") {
                        object[key] = proprety.value.get();
                        return;
                    }

                    if (["Event", "ButiCore"].includes(proprety.value?.constructor.name)) {
                        return;
                    }

                    if (proprety.value?.id) {
                        object[key] = proprety.value.id;
                        return;
                    }

                    if (Array.isArray(proprety.value)) {
                        object[key] = proprety.value;
                        return;
                    }

                    throw new Error(`Prop ${key} not serializable.`);
                }

                object[key] = proprety.value;
            });

            return object;
        }
    }
};

export class Serializable {
    toObject() { }
};


export const propretyNotSerializable = () => {
    return Reflect.metadata(propretyNotSerializableSymbol, true);
};

export const propretyCustomSerializable = (fn: (value: any) => any) => {
    return Reflect.metadata(propretyCustomSerializableSymbol, fn);
};

export default serializable;